import React, { useContext } from "react";
import clsx from "clsx";

import { useQuery } from "@apollo/react-hooks";
import { Card, CardHeader, CardContent, Typography, List, ListItem, Container } from "@material-ui/core";
import { Error, Done, Schedule, LocalShipping } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { useCommonStyles } from "../common.style";
import { DeliveryState, IOrderData, IProductData, myOrdersQuery, productsQuery } from "../queries";
import { useMemberDeliveriesStyles } from "./memberDeliveries.style";
import { zipWithCount } from "../utils";
import { LoadingModal } from "../modals/loadingModal.component";
import { SeasonContext } from "../season/season.context";

export const MemberDeliveriesComponent: React.FC = () => {
  const orderList = useQuery<{
    myOrders: Array<IOrderData>;
  }>(myOrdersQuery);

  const [season] = useContext(SeasonContext);
  const productList = useQuery<{
    products: Array<IProductData>;
  }>(productsQuery, { variables: { season } });

  const { t } = useTranslation();

  const commonStyles = useCommonStyles();
  const classes = useMemberDeliveriesStyles();

  const orders = orderList.data?.myOrders;
  const products = productList.data?.products;

  if (!orders || !products) {
    return <LoadingModal open={true} />;
  }

  const orderedDeliveries = orders
    .flatMap((o) => o.deliveries)
    .map((d) => ({
      ...d,
      date: new Date(Date.parse(d.date)),
    }))
    .sort((a, b) => a.date.valueOf() - b.date.valueOf());

  return (
    <Container component="main" maxWidth="xl" className={clsx(commonStyles.mainContent, classes.page)}>
      <Typography variant="h3" component="h2">
        {t("memberDeliveryList.title")}
      </Typography>
      <section className={classes.deliveryList}>
        {orderedDeliveries.length === 0 && <Typography variant="h4" component="h2">{t("memberDeliveryList.empty")}</Typography>}
        {orderedDeliveries.map((d) => (
          <Card key={d._id}>
            <CardHeader
              avatar={
                d.state === DeliveryState.NoShow ? (
                  <Error />
                ) : d.state === DeliveryState.Ok ? (
                  <Done />
                ) : d.state === DeliveryState.ReScheduled ? (
                  <Schedule />
                ) : (
                  <LocalShipping />
                )
              }
              title={t("memberDeliveryList.delivery.title", { title: d.tourTitle, date: d.date.toLocaleDateString() })}
              subheader={d.stopName}
              action={
                <Typography variant="h5">
                  {d.state === DeliveryState.NoShow
                    ? t("memberDeliveryList.delivery.noShow")
                    : d.state === DeliveryState.Ok
                    ? t("memberDeliveryList.delivery.delivered")
                    : d.state === DeliveryState.ReScheduled
                    ? t("memberDeliveryList.delivery.rescheduled")
                    : t("memberDeliveryList.delivery.scheduled")}
                </Typography>
              }
            />
            <CardContent>
              <List>
                {zipWithCount(d.productIds).map(([count, pid]) => (
                  <ListItem key={`${d._id}_${pid}`}>
                    {products?.find((p) => p._id === pid)?.displayName || "Ismeretlen"} x {count}
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        ))}
      </section>
    </Container>
  );
};
