import { makeStyles, Theme, createStyles } from "@material-ui/core";
import headerImg from "./header.jpg";

export const useLoginStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginView: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    googleButton: {
      marginTop: theme.spacing(1),
      height: 40,
      padding: 8,
      "& img": {
        marginRight: 24,
      },
      fontSize: 14,
      color: "rgba(0,0,0,0.54)",
    },
    headerImg: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      height: "30vh",
      width: "100%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50% 50%",
      backgroundImage: `url("${headerImg}")`,
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(2, 0, 1),
    },
  })
);
