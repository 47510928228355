import React from "react";
import { Card, CardHeader, CardContent, Typography, CardProps, CardActions, Button } from "@material-ui/core";
import { IOrderData, myOrdersQuery } from "../queries";
import { useMemberDeliveriesStyles as useMemberDashboardStyles } from "./memberDashboard.style";
import { sum } from "../utils";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

export const BalanceCardComponent: React.FC<{ cardProps: CardProps; ordersInSeason: IOrderData[] }> = ({
  cardProps,
  ordersInSeason,
}) => {
  const classes = useMemberDashboardStyles();

  const [askInvoice, askInvoiceState] = useMutation(
    gql`
      mutation askForInvoice($id: String!, $count: Int!) {
        askForInvoice(id: $id, count: $count) {
          _id
          invoices {
            _id
            id
            paid
            price
            issued
          }
        }
      }
    `,
    {
      refetchQueries: [{ query: myOrdersQuery }],
      awaitRefetchQueries: true,
    }
  );

  const invoice = (count: number) => {
    Promise.all(ordersInSeason.map((order) => askInvoice({ variables: { id: order._id, count } })));
  };

  const sumPrice = sum(ordersInSeason, (o) => o.offerPriceHUF);
  const invoices = ordersInSeason.flatMap((o) => o.invoices).filter((i) => !i.isReversed);
  const sumPaid = sum(invoices, (i) => i.paid);
  const sumInvoiced = sum(invoices, (i) => i.price);
  // const paidThisMonth = invoices.some((i) => new Date(Date.parse(i.issued)) >= startOfMonth);
  const prepaid = sum(
    ordersInSeason.flatMap((o) => o.prepayments),
    (p) => p.amount
  );

  return (
    <Card {...cardProps}>
      <CardHeader title="Egyenleg" />
      <CardContent>
        <Typography variant="h6" component="p">
          Az éves tagdíjad: {sumPrice}
        </Typography>
        <Typography variant="h6" component="p">
          Ebből befizettél: {sumPaid + prepaid}
        </Typography>
        <Typography
          variant="h6"
          component="p"
          className={sumPaid - sumInvoiced < 0 ? classes.negativeBalance : classes.nonNegBalance}>
          Esedékes fizetnivaló: {sumInvoiced - sumPaid}
        </Typography>
      </CardContent>
      {sumInvoiced - sumPaid <= 0 && sumPrice - sumInvoiced > 100 && (
        <CardActions className={classes.cardActions}>
          <Typography>Szeretnek előtörleszteni, kérek díjbekérőt</Typography>
          <Button size="small" color="primary" onClick={() => invoice(1)} disabled={askInvoiceState.loading} variant="outlined">
            1 havi tagdíjról
          </Button>
          <Button size="small" color="primary" onClick={() => invoice(3)} disabled={askInvoiceState.loading} variant="outlined">
            3 havi tagdíjról
          </Button>
          <Button size="small" color="primary" onClick={() => invoice(12)} disabled={askInvoiceState.loading} variant="outlined">
            Teljes összegről
          </Button>
        </CardActions>
      )}
    </Card>
  );
};
