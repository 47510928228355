import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useContactStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      width: "100%",
      "& h3": {
        marginTop: theme.spacing(2),
      },
    },

    personalInfos: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: theme.spacing(2),
    },
    links: {
      "& h3": {
        [theme.breakpoints.up("sm")]: {
          display: "inline-block",
        },
      },
      "& a": {},
    },
    deliveryCity: {
      marginLeft: "-2rem",
      listStyle: "none",
    },
  })
);
