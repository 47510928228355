export const resources = {
  hu: {
    translation: {
      header: {
        title: "Táncoskert",
        products: "Termékek",
        orders: "Csomagod",
        deliveryPoints: "Átadópontok",
        deliveryTours: "Átadások",
        memberInvoices: "Számláim",
        members: "Tagok",
        memberData: "Adataid",
        motd: "Hirdetmények",
        logout: "Kijelentkezés",
        deliveries: "Átadások",
        contact: "Kapcsolat",
      },
      orders: {
        list: {
          title: "Csomagok",
          empty: "Még nincs rendelésed a rendszerben, adj hozzá újat a cím melletti gombbal",
        },
        card: {
          titleStart: "Előjegyzés",
          noteLabel: "Megjegyzés",

          emptyEdit: "Még nem adtál a csomaghoz terméket, a másik dobozból válogathatsz.",
          empty: "Üres rendelés",

          countLabel: "Darabszám",

          file: "Véglegesítés",
          save: "Mentés",
          discard: "Elvetés",
          accept: "Elfogadom",

          // States
          Created: "Piszkozat",
          Filed: "Beküldve",
          OfferSent: "Ajánlat",
          Accepted: "Folyamatban",
          proposedPrice: "Saccolt ár: {{priceHUF}}ft (havi kb {{mPriceHUF}})",
          offerPrice: "Ár: {{priceHUF}}ft (havi {{mPriceHUF}})",
          invoice: {
            owed: "Díjbekérő ({{issued}} - {{id}}): {{owed}}HUF hátralék",
            paid: "Számla ({{issued}} - {{id}}): {{price}}HUF befizetve",
          },
          delivery: "Átadás {{date}} {{stopName}}",
          getInvoice1: "1 ho fizetes",
          getInvoice3: "3 ho fizetes",
          getInvoice6: "6 ho fizetes",
          getInvoice12: "12 ho fizetes",
          prepayment: "Befizetés: {{reason}} - {{amount}}ft",
        },
      },
      delivery: {
        stop: {
          scheduledDeliveries: "Betervezett",
          orders: "elojegyzes",
          showSums: "Összesítés",
          currentProduct: "Termék",
          scheduled: "Utemezve",
          noShow: "Nem jott",
          reschedule: "Maskor keri",
          ok: "Ok",
          note: "Megjegyzes",
        },
      },
      memberData: {
        title: "Adataid",
        name: "Név",
        phoneNumber: "Telefonszám",
        deliveryPoint: "Kiszállitási hely",
        save: "Mentés",
        billingInfoTitle: "Számlázási adatok",
        billing: {
          name: "Név",
          zip: "Irányítószám",
          city: "Város",
          address: "Cím",
        },
      },
      memberDeliveryList: {
        title: "Átadások",
        empty: "Meg nincs beütemezve semmi!",
        delivery: {
          title: "{{title}}",
          scheduled: "Tervezett",
          delivered: "Átadva",
          noShow: "Nem jött",
          rescheduled: "Máskor",
        }
      },
      memberInvoices: {
        title: "Szamlák és befizetések",
        empty: "Még nincs számlád!",
      },
      motd: {
        editor: {
          title: "Hirdetmények",
        },
        cta: "Értem",
      },
      errors: {
        unknownProduct: "Torolt vagy hibas termek",
        modal: {
          DefaultDeliveryPointNotSet: {
            title: "Kitoltetlen adat!",
            desc:
              "Meg nem allitottad be, hogy hova kered a dolgokat. Ezt fent az adataid menupontban teheted meg. Ha kesz, ne felejtsd el veglegesiteni a rendelesedet.",
            link: "Adataim",
          },
          "Billing info not found": {
            title: "Kitoltetlen szamlazasi adatok!",
            desc:
              'Meg nem adtad meg a szamlazasi adataidat, ezt az "Adataim" menupontban, vagy a lenti linken teheted meg. Ha kesz, ne felejts el dijbekerot kerni.',
            link: "Adataim",
          },
          "There is an active invoice for this order already": {
            title: "Mar van egy aktiv dijbekero",
            desc:
              "Ehhez a csomaghoz mar kikuldtunk egy dijbekerot. Ellenorizd az emailjeidet es/vagy frissitsd az oldalt.",
            link: "",
          },
          TryAgainLater: {
            title: "Valami hiba tortent.",
            desc: "Valami hiba tortent, kerlek probald meg kesobb, vagy szolj rola nekunk!",
            link: "",
          },
        },
      },
      warnings: {
        stock: "Várhatóan várólistás rendelés",
        unsetPrice: "Valamelyik termékhez még nem is saccoltunk árat",
      },
      tourList: {
        deleteTour: {
          title: "Biztos?",
          content: "Torolni szeretned ezt a kiszallitasi kort?",
          doDelete: "Torles",
        },
      },
      tourEditor: {
        deleteStop: {
          title: "Biztos?",
          content: "Torolni szeretned ezt a kiszallitasi helyet?",
          doDelete: "Torles",
        },
      },
      modal: {
        approval: {
          defaultTitle: "Biztos?",
          defaultContinue: "Folytatas",
          cancel: "Megsem",
        },
      },
      contact: {
        title: "Kapcsolat",
      }
    },
  },
};
