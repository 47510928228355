import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import {
  List,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
  ListItem,
  Modal,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import { Add } from "@material-ui/icons";

import { useUserOrderListStyles } from "./userOrderList.style";
import { IOrderData, myOrdersQuery, productsQuery, IProductData, OrderState } from "../queries";
import { OrderCardComponent } from "./orderCard.component";
import { useTranslation } from "react-i18next";
import { SeasonContext } from "../season/season.context";

export const OrderEditComponent: React.FC<{
  order: Omit<IOrderData, "memberEmail" | "unscheduledProducts" | "defaultDeliveryPoint">;
  onClose: (saved: boolean) => void;
  fileOrder: (id: string) => void;
  invoice?: (id: string, count: number) => void;
}> = ({ order, onClose, fileOrder, invoice }) => {
  const [draftOrder, setDraftOrder] = useState(order);

  const [season] = useContext(SeasonContext);
  const productList = useQuery<{
    products: Array<IProductData>;
  }>(productsQuery, { variables: { season } });

  const [addOrder] = useMutation<{
    addOrder: IOrderData;
  }>(
    gql`
      mutation addOrder($products: [OrderInput!]!, $note: String!) {
        addOrder(products: $products, note: $note) {
          _id
          memberEmail
          products {
            productId
            count
          }
          state
          createdDate
          offerDate
          acceptDate
          completeDate
          offerPriceHUF
          userNote
        }
      }
    `,
    {
      refetchQueries: [{ query: myOrdersQuery }],
    }
  );

  const [updateOrder] = useMutation<{ updateOrder: IOrderData }>(
    gql`
      mutation updateOrder($id: String!, $products: [OrderInput!]!, $note: String!) {
        updateOrder(orderId: $id, products: $products, note: $note) {
          _id
          memberEmail
          products {
            productId
            count
          }
          state
          createdDate
          offerDate
          acceptDate
          completeDate
          offerPriceHUF
          userNote
        }
      }
    `,
    {
      refetchQueries: [{ query: myOrdersQuery }],
    }
  );

  function setNote(userNote: string) {
    setDraftOrder((o) => (o ? { ...o, userNote } : o));
  }

  function changeProdCount(id: string, count: number) {
    const prod = productList.data?.products.find((p) => p._id === id);
    if (prod) {
      setDraftOrder((o) => {
        return {
          ...o,
          products:
            count === 0
              ? o.products.filter((p) => p.productId !== id)
              : o.products.some((p) => p.productId === id)
              ? o.products.map((p) =>
                  p.productId !== id ? p : { productId: p.productId, count, unitPrice: prod.priceHUF }
                )
              : o.products.concat([{ productId: id, count, unitPrice: prod.priceHUF }]),
        };
      });
    }
  }

  function addProduct(id: string) {
    const prod = productList.data?.products.find((p) => p._id === id);
    if (prod) {
      setDraftOrder((o) => {
        return {
          ...o,
          products: o.products.some((p) => p.productId === id)
            ? o.products.map((p) =>
                p.productId !== id ? p : { productId: p.productId, count: p.count + 1, unitPrice: prod.priceHUF }
              )
            : o.products.concat([{ productId: id, count: 1, unitPrice: prod.priceHUF }]),
        };
      });
    }
  }

  async function save() {
    if (!draftOrder) {
      return;
    }

    if (draftOrder._id && draftOrder._id !== "new") {
      const res = await updateOrder({
        variables: {
          id: draftOrder._id,
          products: draftOrder.products.map((o) => ({ productId: o.productId, count: o.count })),
          note: draftOrder.userNote || "",
        },
      });
      return res.data && res.data.updateOrder;
    } else {
      if (draftOrder.products.length === 0) {
        return;
      }
      const res = await addOrder({
        variables: {
          products: draftOrder.products.map((o) => ({ productId: o.productId, count: o.count })),
          note: draftOrder.userNote || "",
        },
      });
      return res.data && res.data.addOrder;
    }
  }

  const { t } = useTranslation();
  const classes = useUserOrderListStyles();

  return (
    <Modal
      open={!!draftOrder}
      className={classes.editModal}
      BackdropProps={{
        className: classes.modalBackdrop,
      }}
      onBackdropClick={async () => {
        if (![OrderState.Accepted, OrderState.Completed].includes(draftOrder.state)) {
          await save();
          onClose(true);
        } else {
          onClose(false);
        }
      }}
      onEscapeKeyDown={async () => {
        if (![OrderState.Accepted, OrderState.Completed].includes(draftOrder.state)) {
          await save();
          onClose(true);
        } else {
          onClose(false);
        }
      }}>
      <>
        {draftOrder && (
          <OrderCardComponent
            key={draftOrder._id + "_draft"}
            order={draftOrder}
            origOrder={order._id !== "new" ? order : undefined}
            setNote={setNote}
            changeProdCount={changeProdCount}
            canEdit={true}
            actions={[
              {
                primary: true,
                text: t("orders.card.save"),
                disabled: draftOrder._id === "new" && draftOrder.products.length === 0,
                cb: async () => {
                  await save();
                  onClose(true);
                },
              },
              invoice &&
              draftOrder.offerPriceHUF &&
              draftOrder.offerPriceHUF >
                draftOrder.invoices.reduce((s, c) => s + c.price, 0) + draftOrder.offerPriceHUF / 12
                ? {
                    primary: true,
                    text: t("orders.card.getInvoice1"),
                    cb: () => invoice(draftOrder._id, 1),
                  }
                : undefined,
              invoice &&
              draftOrder.offerPriceHUF &&
              draftOrder.offerPriceHUF >
                draftOrder.invoices.reduce((s, c) => s + c.price, 0) + draftOrder.offerPriceHUF / 4
                ? {
                    primary: true,
                    text: t("orders.card.getInvoice3"),
                    cb: () => invoice(draftOrder._id, 3),
                  }
                : undefined,
              invoice &&
              draftOrder.offerPriceHUF &&
              draftOrder.offerPriceHUF >
                draftOrder.invoices.reduce((s, c) => s + c.price, 0) + draftOrder.offerPriceHUF / 2
                ? {
                    primary: true,
                    text: t("orders.card.getInvoice12"),
                    cb: () => invoice(draftOrder._id, 12),
                  }
                : undefined,
              draftOrder.state === OrderState.Created
                ? {
                    primary: true,
                    disabled: draftOrder.products.length === 0,
                    text: t("orders.card.file"),
                    cb: async () => {
                      const res = await save();
                      if (res) {
                        await fileOrder(res._id);
                        onClose(true);
                      }
                    },
                  }
                : undefined,
              {
                primary: false,
                text: t("orders.card.discard"),
                cb: () => onClose(false),
              },
            ]}
          />
        )}
        <Card>
          <CardHeader title="Termékek" />
          <CardContent>
            <List>
              {productList.data &&
                productList.data.products
                  .filter(p => p.stockBySeason !== undefined && p.stockBySeason > 0)
                  .map((p, ind) => {
                  return (
                    <ListItem key={`${p._id}_${ind}`}>
                      <ListItemText secondary={p.description}>{p.displayName}</ListItemText>
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => addProduct(p._id)}>
                          <Add />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </List>
          </CardContent>
        </Card>
      </>
    </Modal>
  );
};
