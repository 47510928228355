import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import {
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  Container,
  ListItemSecondaryAction,
  Modal,
  Paper,
  Button,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";

import { useCommonStyles } from "../common.style";
import { deliveryPointsQuery, IDeliveryPointData } from "../queries";
import { useProductListStyles } from "./deliveryPointList.style";
import { Delete, Edit } from "@material-ui/icons";
import { useModalStyles } from "../modals/modal.style";

export const DeliveryPointList: React.FC = () => {
  const { loading, error, data } = useQuery<{
    deliveryPoints: Array<IDeliveryPointData>;
  }>(deliveryPointsQuery);

  const [addDeliveryPoint] = useMutation<{ addDeliveryPoint: IDeliveryPointData }>(
    gql`
      mutation addDeliveryPoint($displayName: String!, $addr: String!, $loc: String!, $calendarId: String!) {
        addDeliveryPoint(name: $displayName, address: $addr, location: $loc, calendarId: $calendarId) {
          _id
          displayName
          address
          location
          calendarId
        }
      }
    `,
    {
      update(cache, { data }) {
        const cachedData = cache.readQuery<{
          deliveryPoints: Array<IDeliveryPointData>;
        }>({ query: deliveryPointsQuery })!;

        if (data) {
          cache.writeQuery({
            query: deliveryPointsQuery,
            data: { deliveryPoints: cachedData.deliveryPoints.concat([data.addDeliveryPoint]) },
          });
        }
      },
    }
  );

  const [deleteDeliveryPoint] = useMutation<{ deleteDeliveryPoint: IDeliveryPointData }>(
    gql`
      mutation deleteDeliveryPoint($id: String!) {
        deleteDeliveryPoint(id: $id) {
          _id
        }
      }
    `,
    {
      update(cache, { data }) {
        const cachedData = cache.readQuery<{
          deliveryPoints: Array<IDeliveryPointData>;
        }>({ query: deliveryPointsQuery })!;

        if (data) {
          cache.writeQuery({
            query: deliveryPointsQuery,
            data: { deliveryPoints: cachedData.deliveryPoints.filter((p) => p._id !== data.deleteDeliveryPoint._id) },
          });
        }
      },
    }
  );

  const [updateDeliveryPoint, updateDeliveryPointState] = useMutation<{ updateDeliveryPoint: IDeliveryPointData }>(
    gql`
      mutation updateDeliveryPoint(
        $id: String!
        $displayName: String!
        $addr: String!
        $loc: String!
        $calendarId: String!
      ) {
        updateDeliveryPoint(id: $id, name: $displayName, address: $addr, location: $loc, calendarId: $calendarId) {
          _id
          displayName
          address
          location
          calendarId
        }
      }
    `
  );

  const [displayName, setDisplayName] = useState("");
  const [addr, setAddr] = useState("");
  const [loc, setLoc] = useState("");
  const [calendarId, setCalendarId] = useState("");

  const classes = useProductListStyles();

  const [editedPoint, setEditedPoint] = useState<string | undefined>(undefined);

  const modalClasses = useModalStyles();

  function clear() {
    setDisplayName("");
    setAddr("");
    setLoc("");
    setEditedPoint(undefined);
  }

  return (
    <Container component="main" maxWidth="xl" className={useCommonStyles().mainContent}>
      <Typography variant="h3" component="h2">
        Új kiszállítási pont
      </Typography>
      <form className={classes.productForm}>
        <div>
          <TextField
            onChange={(ev) => setDisplayName(ev.currentTarget.value)}
            value={displayName}
            label="Név"
            fullWidth={true}
          />
          <TextField
            onChange={(ev) => setAddr(ev.currentTarget.value)}
            value={addr}
            placeholder="Cím"
            multiline={true}
            fullWidth={true}
          />
          <TextField
            onChange={(ev) => setLoc(ev.currentTarget.value)}
            value={loc}
            placeholder="Koordináták"
            multiline={true}
            fullWidth={true}
          />
        </div>
        <IconButton
          onClick={() =>
            addDeliveryPoint({
              variables: {
                displayName,
                addr,
                loc,
                calendarId,
              },
            })
          }>
          <AddIcon />
        </IconButton>
      </form>
      <Typography variant="h3" component="h2">
        Kiszállítási pontok
      </Typography>
      <List>
        {data && data.deliveryPoints.length === 0 && (
          <Typography variant="h4" component="p">
            Sajnos még nincs itt semmi
          </Typography>
        )}
        {loading && <CircularProgress />}
        {error && error.message}
        {data &&
          data.deliveryPoints.map((p) => (
            <ListItem key={p._id}>
              <ListItemText secondary={`${p.address} (${p.location})`}>{p.displayName}</ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => {
                    setDisplayName(p.displayName);
                    setAddr(p.address);
                    setLoc(p.location);
                    setCalendarId(p.calendarId || "");
                    return setEditedPoint(p._id);
                  }}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => deleteDeliveryPoint({ variables: { id: p._id } })}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
      <Modal open={!!editedPoint} className={modalClasses.loadingModal}>
        <Paper className={modalClasses.modalPaper}>
          <Typography variant="h3" component="h2">
            Szerkesztes
          </Typography>
          <form className={classes.productForm}>
            <div>
              <TextField
                onChange={(ev) => setDisplayName(ev.currentTarget.value)}
                value={displayName}
                label="Név"
                fullWidth={true}
              />
              <TextField
                onChange={(ev) => setAddr(ev.currentTarget.value)}
                value={addr}
                placeholder="Cím"
                multiline={true}
                fullWidth={true}
              />
              <TextField
                onChange={(ev) => setLoc(ev.currentTarget.value)}
                value={loc}
                placeholder="Koordináták"
                multiline={true}
                fullWidth={true}
              />
              <TextField
                onChange={(ev) => setCalendarId(ev.currentTarget.value)}
                value={calendarId}
                placeholder="CalendarId"
                multiline={true}
                fullWidth={true}
              />
            </div>
          </form>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              !updateDeliveryPointState.loading &&
              updateDeliveryPoint({
                variables: {
                  id: editedPoint,
                  displayName,
                  addr,
                  loc,
                  calendarId,
                },
              }).then(() => clear())
            }>
            {updateDeliveryPointState.loading ? <CircularProgress size="1em" /> : "Mentes"}
          </Button>
        </Paper>
      </Modal>
    </Container>
  );
};
