import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import {
  Typography,
  CircularProgress,
  TextField,
  IconButton,
  Container,
  Modal,
  Paper,
  Button,
  TableRow,
  TableHead,
  Table,
  TableCell,
  TableBody,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

import Delete from "@material-ui/icons/Delete";

import { useCommonStyles } from "../common.style";
import { productsQuery, IProductData } from "../queries";
import { useProductListStyles } from "./productList.style";
import { Edit, Add } from "@material-ui/icons";
import { useModalStyles } from "../modals/modal.style";
import { SeasonContext } from "../season/season.context";

export const ProductList: React.FC = () => {
  const [season] = useContext(SeasonContext);
  const { loading, error, data } = useQuery<{
    products: Array<IProductData>;
  }>(productsQuery, { variables: { season } });

  const [addProduct, addState] = useMutation<{ addProduct: IProductData }>(
    gql`
      mutation addProduct(
        $displayName: String!
        $desc: String!
        $vat: Float!
        $nonDepleting: Boolean!
        $invoiceName: String!
      ) {
        addProduct(
          displayName: $displayName
          description: $desc
          vat: $vat
          nonDepleting: $nonDepleting
          invoiceName: $invoiceName
        ) {
          _id
          displayName
          description
          priceHUF
          stock
          vat
          nonDepleting
          invoiceName
        }
      }
    `,
    {
      update(cache, { data }) {
        const cachedData = cache.readQuery<{
          products: Array<IProductData>;
        }>({ query: productsQuery })!;

        if (data) {
          console.log(data.addProduct, cachedData.products.length, cachedData.products.concat([data.addProduct]));
          cache.writeQuery({
            query: productsQuery,
            variables: { season },
            data: { products: cachedData.products.concat([data.addProduct]) },
          });
        }
      },
    }
  );
  const [deleteProduct, deleteState] = useMutation<{ deleteProduct: string }>(
    gql`
      mutation deleteProduct($id: String!) {
        deleteProduct(id: $id)
      }
    `,
    {
      refetchQueries: [{ query: productsQuery, variables: { season } }],
    }
  );

  const [updateProduct, updateProductState] = useMutation<{ updateProduct: IProductData }>(
    gql`
      mutation updateProduct(
        $id: String!
        $displayName: String!
        $desc: String!
        $vat: Int!
        $nonDepleting: Boolean!
        $invoiceName: String!
      ) {
        updateProduct(
          id: $id
          displayName: $displayName
          description: $desc
          vat: $vat
          nonDepleting: $nonDepleting
          invoiceName: $invoiceName
        ) {
          _id
          displayName
          description
          priceHUF
          stock
          vat
          nonDepleting
          invoiceName
        }
      }
    `,
    {
      refetchQueries: [{ query: productsQuery, variables: { season } }],
    }
  );

  const [savePrice] = useMutation<{ setPrice: IProductData }>(
    gql`
      mutation setPrice($id: String!, $price: Int!) {
        setPrice(id: $id, price: $price) {
          _id
          priceHUF
        }
      }
    `,
    {
      refetchQueries: [{ query: productsQuery, variables: { season } }],
    }
  );

  const [saveStock] = useMutation<{ setPrice: IProductData }>(
    gql`
      mutation setStock($id: String!, $season: Int!, $stock: Int!) {
        setStock(id: $id, season: $season, stock: $stock) {
          _id
          stock
        }
      }
    `,
    {
      refetchQueries: [{ query: productsQuery, variables: { season } }],
    }
  );

  const [displayName, setDisplayName] = useState("");
  const [desc, setDesc] = useState("");
  const [vat, setVat] = useState<number>(5);
  const [nonDepleting, setNonDepleting] = useState<boolean>(false);
  const [invoiceName, setInvoiceName] = useState<string>("");
  const [stock, setStock] = useState<number | undefined>(undefined);
  const [price, setPrice] = useState<number | undefined>(undefined);

  const [editedProduct, setEditedProduct] = useState<string | undefined>(undefined);

  const classes = useProductListStyles();
  const modalClasses = useModalStyles();

  function clear() {
    setDisplayName("");
    setDesc("");
    setVat(5);
    setNonDepleting(false);
    setInvoiceName("");
    setPrice(undefined);
    setStock(undefined);
    setEditedProduct(undefined);
  }

  async function save() {
    if (updateProductState.loading || addState.loading) {
      return;
    }

    let id = editedProduct;
    if (editedProduct === "new") {
      const added = await addProduct({
        variables: {
          displayName,
          desc,
          vat,
          nonDepleting,
          invoiceName,
        },
      });
      if (!added.data) {
        throw new Error("AddProductFailed");
      }

      id = added.data.addProduct._id;
    } else {
      await updateProduct({
        variables: {
          id,
          displayName,
          desc,
          vat,
          nonDepleting,
          invoiceName,
        },
      });
    }

    if (price !== undefined && price !== null) {
      await savePrice({
        variables: {
          id,
          price,
        },
      });
    }

    if (stock !== undefined && stock !== null) {
      await saveStock({
        variables: {
          id,
          season,
          stock,
        },
      });
    }
    clear();
  }

  return (
    <Container component="main" maxWidth="xl" className={useCommonStyles().mainContent}>
      <Typography variant="h3" component="h2">
        Hozzáadott termékek
        <IconButton onClick={() => setEditedProduct("new")} color="primary">
          <Add />
        </IconButton>
      </Typography>
      {loading && <CircularProgress />}
      {error && error.message}
      {data && data.products && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nev</TableCell>
              <TableCell align="right">Keszlet</TableCell>
              <TableCell align="right">Maradt</TableCell>
              <TableCell align="right">Nem veglegesitett</TableCell>
              <TableCell align="right">Ar</TableCell>
              <TableCell align="right">Elojegyzett Ar</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.products.length === 0 && (
              <Typography variant="h4" component="p">
                Sajnos még nincs itt semmi
              </Typography>
            )}
            {data &&
              data.products.map((p) => (
                <TableRow key={p._id}>
                  <TableCell>{p.displayName}</TableCell>
                  <TableCell align="right">{p.stockBySeason}</TableCell>
                  <TableCell align="right">{p.remainingStock}</TableCell>
                  <TableCell align="right">{p.inBasket}</TableCell>
                  <TableCell align="right">{p.priceHUF}</TableCell>
                  <TableCell align="right">
                    {p.priceHUF && p.stockBySeason && p.priceHUF * (p.stockBySeason - p.remainingStock!)}
                  </TableCell>
                  <TableCell padding="none" size="small">
                    <IconButton
                      onClick={() => {
                        setDisplayName(p.displayName);
                        setDesc(p.description);
                        setStock(p.stockBySeason);
                        setPrice(p.priceHUF);
                        setVat(p.vat);
                        setNonDepleting(!!p.nonDepleting);
                        setInvoiceName(p.invoiceName || "");
                        return setEditedProduct(p._id);
                      }}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => !deleteState.loading && deleteProduct({ variables: { id: p._id } })}>
                      {deleteState.loading ? <CircularProgress size="1em" /> : <Delete />}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
      <Modal
        open={!!editedProduct}
        className={modalClasses.loadingModal}
        onBackdropClick={() => setEditedProduct(undefined)}
        onEscapeKeyDown={() => setEditedProduct(undefined)}>
        <Paper className={modalClasses.modalPaper}>
          <Typography variant="h3" component="h2">
            Szerkesztes
          </Typography>
          <form className={classes.productForm}>
            <div>
              <TextField
                onChange={(ev) => setDisplayName(ev.currentTarget.value)}
                value={displayName}
                label="Nev"
                fullWidth={true}
              />
              <TextField
                onChange={(ev) => setDesc(ev.currentTarget.value)}
                value={desc}
                label="Leiras"
                multiline={true}
                fullWidth={true}
              />
              <TextField
                onChange={(ev) => setVat(Number.parseInt(ev.currentTarget.value))}
                value={vat}
                label="ÁFA"
                type="number"
              />
              <br />
              <TextField
                onChange={(ev) => setStock(Number.parseInt(ev.currentTarget.value))}
                value={stock}
                label="Készlet"
                type="number"
              />
              <br />
              <TextField
                onChange={(ev) => setPrice(Number.parseInt(ev.currentTarget.value))}
                value={price}
                label="Ár"
                type="number"
              />
              <br />
              <FormControlLabel
                label="Nem fogy el"
                control={<Switch title="Nem fogy el" checked={nonDepleting} onChange={(_, a) => setNonDepleting(a)} />}
              />
              <TextField
                onChange={(ev) => setInvoiceName(ev.currentTarget.value)}
                value={invoiceName}
                label="Szamla nev"
                fullWidth={true}
              />
            </div>
          </form>
          <Button variant="contained" color="primary" onClick={() => save()}>
            {updateProductState.loading ? <CircularProgress size="1em" /> : "Mentes"}
          </Button>
        </Paper>
      </Modal>
    </Container>
  );
};
