import { gql } from "apollo-boost";

export const productsQuery = gql`
  query products($season: Int!) {
    products {
      _id
      displayName
      description
      priceHUF
      stockBySeason(season: $season)
      remainingStock(season: $season)
      inBasket(season: $season)
      vat
      nonDepleting
      invoiceName
    }
  }
`;

export interface IProductData {
  _id: string;
  displayName: string;
  description: string;
  priceHUF?: number;
  stockBySeason?: number;
  remainingStock?: number;
  inBasket?: number;
  vat: number;
  nonDepleting: boolean;
  invoiceName?: string;
}

export const allToursQuery = gql`
  query tours {
    deliveryTours {
      _id
      title
      date
      stops {
        _id
        deliveryPoint {
          _id
          displayName
        }
        deliveries {
          _id
          email
          productIds
          state
        }
      }
    }
  }
`;

export const tourQuery = gql`
  query deliveryTour($id: String!) {
    deliveryTour(id: $id) {
      _id
      title
      date
      stops {
        _id
        deliveryPoint {
          _id
          displayName
        }
        deliveries {
          _id
          email
          productIds
          state
        }
      }
    }
  }
`;

export const stopQuery = gql`
  query deliveryStop($id: String!) {
    deliveryStop(id: $id) {
      _id
      deliveryPoint {
        _id
        displayName
      }
      deliveries {
        _id
        email
        productIds
        state
        note
      }
    }
  }
`;

export interface IDeliveryData {
  _id: string;
  email: string;
  productIds: string[];
  state: DeliveryState;
  note: string;
}

export interface IStopData {
  _id: string;
  deliveryPoint: { _id: string; displayName: string };
  deliveries: Array<IDeliveryData>;
}

export interface ITourData {
  _id: string;
  title: string;
  date: string;
  stops: Array<IStopData>;
}

export interface ISubscriptionTypeData {
  _id: string;
  displayName: string;
  description: string;
}

export const myOrdersQuery = gql`
  query myOrders {
    myOrders {
      _id
      memberEmail
      products {
        productId
        count
        unitPrice
      }

      unscheduledProducts {
        productId
        count
        unitPrice
      }

      state
      season
      createdDate
      offerDate
      acceptDate
      completeDate
      offerPriceHUF
      userNote
      deliveries {
        _id
        stopName
        date
        productIds
        state
        tourTitle
      }
      invoices {
        _id
        id
        paid
        price
        issued
      }
      paymentState
      prepayments {
        _id
        reason
        amount
      }
    }
  }
`;

export const allOrdersQuery = gql`
  query orders($allowedStates: [String!]) {
    orders(states: $allowedStates) {
      _id
      memberEmail
      products {
        productId
        count
        unitPrice
      }
      unscheduledProducts {
        productId
        count
      }
      defaultDeliveryPoint {
        _id
      }
      state
      season
      createdDate
      offerDate
      acceptDate
      completeDate
      offerPriceHUF
      userNote
      deliveries {
        _id
        stopName
        state
        date
        productIds
        state
        note
      }
      hidden
      invoices {
        _id
        id
        paid
        price
        issued
      }
      paymentState
      prepayments {
        _id
        reason
        amount
      }
    }
  }
`;

export const orderQuery = gql`
  query order($id: String!) {
    order(id: $id) {
      _id
      memberEmail
      products {
        productId
        count
      }
      unscheduledProducts {
        productId
        count
      }
      defaultDeliveryPoint {
        _id
      }
      state
      paymentState
      createdDate
      offerDate
      acceptDate
      completeDate
      offerPriceHUF
      userNote
      hidden
    }
  }
`;

export enum DeliveryState {
  Scheduled = "Scheduled",
  ReScheduled = "ReScheduled",
  NoShow = "NoShow",
  Ok = "Ok",
}

export enum OrderState {
  Created = "Created",
  Filed = "Filed",
  OfferSent = "OfferSent",
  Accepted = "Accepted",
  Completed = "Completed",
}

export enum OrderPaymentState {
  MonthUninvoiced = "MonthUninvoiced",
  MonthInvoiced = "MonthInvoiced",
  MonthPaid = "MonthPaid",
  FullyPaid = "FullyPaid",
}

export interface IInvoice {
  _id: string;
  id: string;
  email: string;
  orderId: string;
  price: number;
  paid: number;
  isReversed: boolean;
  issued: string;
}

export type Prepayment = {
  _id: string;
  amount: number;
  reason: string;
};

export interface IOrderData {
  _id: string;
  memberEmail: string;
  products: { productId: string; count: number; unitPrice: number | undefined }[];
  unscheduledProducts: { productId: string; count: number }[];
  defaultDeliveryPoint: { _id: string };
  state: OrderState;
  season: number;
  createdDate: string;
  offerDate?: string;
  acceptDate?: string;
  completeDate?: string;
  offerPriceHUF?: number;
  userNote?: string;
  hidden?: boolean;
  invoices: IInvoice[];
  prepayments: Prepayment[];
  paymentState: OrderPaymentState;
  deliveries: Array<{
    _id: string;
    productIds: string[];
    tourTitle?: string;
    stopName: string;
    date: string;
    state: DeliveryState;
  }>;
}

export const deliveryPointsQuery = gql`
  query points {
    deliveryPoints {
      _id
      displayName
      address
      location
      calendarId
    }
  }
`;

export interface IDeliveryPointData {
  _id: string;
  displayName: string;
  address: string;
  location: string;
  calendarId?: string;
}

export const meQuery = gql`
  query me {
    me {
      _id
      email
      fullName
      phoneNumber
      isAdmin
      defaultDeliveryPoint {
        _id
        displayName
        address
        location
      }
    }
    motd
    currentPolls {
      _id
      title
      description
      fromDate
      untilDate
      maxChoice
      options {
        _id
        displayName
        description
        votes
      }
    }
  }
`;

export interface IMemberData {
  _id: string;
  email: string;
  fullName: string;
  phoneNumber: string;
  isAdmin: boolean;
  isEmailVerified: boolean;
  defaultDeliveryPoint: IDeliveryPointData;
}

export const usersQuery = gql`
  query users {
    users {
      email
      fullName
      phoneNumber
      isAdmin
      isEmailVerified
      defaultDeliveryPoint {
        _id
        displayName
      }
    }
  }
`;
