import React, { useLayoutEffect } from "react";

import { Typography, Container, CircularProgress, Button, Avatar } from "@material-ui/core";
import { useLocation, Link } from "react-router-dom";
import clsx from "clsx";

import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import { useCommonStyles } from "../common.style";
import { useLoginStyles } from "./login.style";
import { LockOutlined } from "@material-ui/icons";

export const EmailVerificationComponent: React.FC = () => {
  const commonClasses = useCommonStyles();
  const classes = useLoginStyles();

  const p = useLocation();
  const token = p.hash.substr(1);

  const [verifyEmail, verifyEmailState] = useMutation<{ verifyEmail: boolean }>(gql`
    mutation verifyEmail($token: String!) {
      verifyEmail(token: $token)
    }
  `);

  useLayoutEffect(() => {
    if (token) {
      verifyEmail({
        variables: { token },
      });
    }
  }, [token, verifyEmail]);

  return (
    <Container component="main" className={clsx(commonClasses.mainContent, classes.loginView)}>
      <Link to="/" className={commonClasses.plainLink}>
        <Typography component="h1" variant="h2">
          Táncoskert
        </Typography>
      </Link>
      <div className={classes.headerImg} />
      <Avatar className={classes.avatar}>
        <LockOutlined />
      </Avatar>
      <Typography component="h2" variant="h5">
        Email megerősítés
      </Typography>

      <Container maxWidth="sm">
        {!verifyEmailState.called || verifyEmailState.loading ? (
          <CircularProgress />
        ) : verifyEmailState.data && verifyEmailState.data.verifyEmail ? (
          <>
            <Typography component="p" variant="h6">
              Köszönjük, most már be tudsz jelentkezni.
            </Typography>
            <Button href="/">Bejelentkezes</Button>
          </>
        ) : (
          <>
            <Typography component="p" variant="h6">
              Valami hiba történt
            </Typography>
          </>
        )}
      </Container>
    </Container>
  );
};
