import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: theme.spacing(1),
      "@media print": {
        display: "none",
      },
    },
    headerNav: {
      "& a": {
        textDecoration: "none",
        color: "inherit",
        margin: theme.spacing(1),
        "&.active": {
          textDecoration: "underline",
          fontWeight: "bolder",
        },
      },
    },
    headerUserMenu: {
      display: "flex",
      flexDirection: "row",
    },
    wideOnly: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    narrowOnly: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    root: {
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    },
  })
);
