import React, { useState, useEffect } from "react";

import { Tooltip, TooltipProps } from "@material-ui/core";

export const ImmediateTooltipComponent: React.FC<TooltipProps> = ({ children, ...props }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => setOpen(false), 5000);
      return () => clearTimeout(timeout);
    }
  });

  return (
    <Tooltip {...props} open={isOpen} onClick={() => setOpen((b) => !b)} onMouseEnter={() => setOpen(true)}>
      {children}
    </Tooltip>
  );
};
