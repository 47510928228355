import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import clsx from "clsx";

import {
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Container,
  ListItemSecondaryAction,
  CardContent,
  CardHeader,
  Card,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  ListItemAvatar,
  Avatar,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Backspace";

import { useParams } from "react-router-dom";

import { count, sum, zipWithCount } from "../utils";
import { useCommonStyles } from "../common.style";
import { useStopEditorStyles } from "./deliveryStopEditor.style";

import {
  ITourData,
  IStopData,
  stopQuery,
  tourQuery,
  allOrdersQuery,
  IOrderData,
  productsQuery,
  IProductData,
  orderQuery,
  IMemberData,
  usersQuery,
  OrderState,
  DeliveryState,
} from "../queries";
import { Done } from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import { SeasonContext } from "../season/season.context";

export const DeliveryStopEditor: React.FC = () => {
  const { t } = useTranslation();

  const params = useParams<{ tourId: string; stopId: string }>();

  const userList = useQuery<{
    users: Array<IMemberData>;
  }>(usersQuery);

  const [season] = useContext(SeasonContext);
  const productList = useQuery<{
    products: Array<IProductData>;
  }>(productsQuery, { variables: { season } });

  const deliveryTour = useQuery<{
    deliveryTour: ITourData;
  }>(tourQuery, {
    variables: {
      id: params.tourId,
    },
  });

  const deliveryStop = useQuery<{
    deliveryStop: IStopData;
  }>(stopQuery, {
    variables: {
      id: params.stopId,
    },
  });

  const orderList = useQuery<{
    orders: IOrderData[];
  }>(allOrdersQuery);

  const [addToDeliveryMut, addState] = useMutation<{ addToDelivery: ITourData }>(
    gql`
      mutation addToDelivery($stopId: String!, $email: String!, $orderId: String!, $productId: String!) {
        addToDelivery(stopId: $stopId, email: $email, orderId: $orderId, productId: $productId) {
          _id
          deliveryPoint {
            _id
            displayName
          }
          deliveries {
            _id
            email
            productIds
            state
          }
        }
      }
    `
  );
  function addToDelivery(variables: { stopId: string; email: string; orderId: string; productId: string }) {
    return addToDeliveryMut({
      variables,
      update: (store, { data: res }) => {
        if (res && res.addToDelivery) {
          // Read the data from our cache for this query.
          const data = store.readQuery<{ order: IOrderData }>({
            query: orderQuery,
            variables: { id: variables.orderId },
          });

          if (data && data.order) {
            // Write our data back to the cache.
            store.writeQuery({
              query: orderQuery,
              variables: { id: variables.orderId },
              data: {
                order: {
                  ...data.order,
                  unscheduledProducts: data.order.unscheduledProducts.map((p) =>
                    p.productId === variables.productId ? { ...p, count: p.count - 1 } : p
                  ),
                },
              },
            });
          }
        }
      },
    });
  }

  const [removeFromDeliveryMut, removeState] = useMutation<{ removeFromDelivery: ITourData }>(
    gql`
      mutation removeFromDelivery($deliveryId: String!, $productId: String!) {
        removeFromDelivery(id: $deliveryId, productId: $productId) {
          _id
          email
          productIds
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: stopQuery,
          variables: {
            id: params.stopId,
          },
        },
      ],
    }
  );
  const commonStyles = useCommonStyles();
  const stopEditorStyles = useStopEditorStyles();

  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [doneOrders, setDoneOrders] = useState<Set<string>>(new Set());
  const [displaySum, setDisplaySum] = useState(false);
  const [showAll, setShowAll] = useState(false);

  function markDoneOrder(id: string) {
    setDoneOrders((os) => new Set(os).add(id));
  }

  if (
    !userList.called ||
    userList.loading ||
    !deliveryTour.called ||
    deliveryTour.loading ||
    !deliveryStop.called ||
    deliveryStop.loading ||
    !orderList.called ||
    orderList.loading ||
    !productList.called ||
    productList.loading
  ) {
    return (
      <Container component="main" maxWidth="xl" className={commonStyles.mainContent}>
        <CircularProgress />
      </Container>
    );
  }

  if (!deliveryTour.data || !deliveryStop.data || !orderList.data || !productList.data || !userList.data) {
    return (
      <Container component="main" maxWidth="xl" className={commonStyles.mainContent}>
        <p>{userList.error && userList.error.message}</p>
        <p>{deliveryTour.error && deliveryTour.error.message}</p>
        <p>{deliveryStop.error && deliveryStop.error.message}</p>
        <p>{orderList.error && orderList.error.message}</p>
        <p>{productList.error && productList.error.message}</p>
      </Container>
    );
  }

  const users = userList.data.users;
  const tour = deliveryTour.data.deliveryTour;
  const stop = deliveryStop.data.deliveryStop;
  const products = productList.data.products;
  const orders = orderList.data.orders;

  const deliveredProds = stop.deliveries
    .filter(d => d.state !== DeliveryState.ReScheduled)
    .flatMap((s) => s.productIds)
    .map((pid) => products.find((p) => p._id === pid)?.displayName);

  const summedProducts = Array.from(new Set(deliveredProds)).map((name) => [
    name,
    deliveredProds.reduce((acc, cid) => (cid === name ? acc + 1 : acc), 0),
  ]);

  return (
    <Container component="main" maxWidth="xl" className={clsx(commonStyles.mainContent)}>
      <Typography variant="h2">
        {tour.title} - {stop.deliveryPoint.displayName} {new Date(tour.date).toLocaleDateString()}
      </Typography>
      <div className={stopEditorStyles.stopView}>
        <section className={stopEditorStyles.stopColumn}>
          <Typography variant="h3">{t("delivery.stop.scheduledDeliveries")}</Typography>

          <FormControlLabel
            control={<Switch onChange={() => setDisplaySum((b) => !b)} name="showSums" />}
            label={t("delivery.stop.showSums")}
          />
          <List>
            {displaySum
              ? summedProducts.map(([name, count]) => (
                  <ListItem key={`sum_delivery_${name}`}>
                    <ListItemAvatar>
                      <Avatar>{count}</Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={name} />
                  </ListItem>
                ))
              : stop.deliveries.map((d) => {
                  const u = users.find((u) => u.email === d.email);
                  const countedDeliveries = Array.from(new Set(d.productIds)).map((name) => [
                    name,
                    d.productIds.reduce((acc, cname) => (cname === name ? acc + 1 : acc), 0),
                  ]);
                  return (
                    <Card key={d._id}>
                      <CardHeader title={u ? `${u.fullName} (${u.phoneNumber})` : d.email} />
                      <CardContent>
                        <List>
                          {countedDeliveries.map(([pid, count]) => {
                            const name = products.find((p) => p._id === pid)?.displayName || t("errors.unknownProduct");
                            return (
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar>{count}</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={name} />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    onClick={() => {
                                      !removeState.loading &&
                                        removeFromDeliveryMut({
                                          variables: {
                                            deliveryId: d._id,
                                            productId: pid,
                                          },
                                        });
                                    }}>
                                    {removeState.loading ? <CircularProgress /> : <RemoveIcon />}
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                        </List>
                      </CardContent>
                    </Card>
                  );
                })}
          </List>
        </section>
        <section className={stopEditorStyles.stopColumn}>
          <Typography variant="h3">{t("delivery.stop.orders")}</Typography>

          <FormControl fullWidth={true}>
            <InputLabel> {t("delivery.stop.currentProduct")} </InputLabel>
            <Select
              onChange={(ev) => {
                return setSelectedProduct(ev.target.value as string);
              }}
              value={selectedProduct}>
              {products.map((p) => (
                <MenuItem key={`curr_prod_${p._id}`} value={p._id}>
                  {p.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            label="Mindenki"
            checked={showAll}
            onChange={(_, v) => setShowAll(v)}
            control={<Switch />}
          />
          {orders
            .filter(
              (o) =>
                (showAll ||
                  (o.defaultDeliveryPoint &&
                    o.defaultDeliveryPoint._id === stop.deliveryPoint._id &&
                    o.state === OrderState.Accepted)) &&
                !doneOrders.has(o._id) &&
                (selectedProduct
                  ? o.unscheduledProducts.some((p) => p.productId === selectedProduct && p.count > 0)
                  : o.unscheduledProducts.some((p) => p.count > 0))
            )
            .map((o) => {
              const u = users.find((u) => u.email === o.memberEmail);
              return (
                <Card>
                  <CardHeader
                    title={(u?.fullName ?? o.memberEmail) + "@" + o.season}
                    subheader={o.userNote}
                    action={<IconButton onClick={() => markDoneOrder(o._id)}>
                      <Done />
                    </IconButton>} />
                  <CardContent>
                    <List key={o._id}>
                      {o.unscheduledProducts.filter(({ count }) => count !== 0).map(({ productId, count }) => (
                        <ListItem key={`${o._id}-${productId}`}>
                          <ListItemAvatar>
                            <Avatar>{count}</Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={products.find((p) => p._id === productId)?.displayName} />
                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={() => !addState.loading &&
                                addToDelivery({
                                  stopId: stop._id,
                                  email: o.memberEmail,
                                  orderId: o._id,
                                  productId: productId,
                                })}>
                              {addState.loading ? <CircularProgress /> : <AddIcon />}
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              );
            })}
        </section>
      </div>
    </Container>
  );
};
