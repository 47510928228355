import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  ListItemSecondaryAction,
  Card,
  CardHeader,
  CardContent,
  Button,
  CardActions,
  ListItemAvatar,
} from "@material-ui/core";

import { Delete, Edit, InfoOutlined, WarningOutlined, Error, Done, Schedule, LocalShipping } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { useCommonStyles } from "../common.style";
import { IOrderData, IProductData, productsQuery, DeliveryState } from "../queries";
import { useUserOrderListStyles } from "./userOrderList.style";
import { ImmediateTooltipComponent } from "./immediateTooltip.component";
import { zipWithCount } from "../utils";
import { SeasonContext } from "../season/season.context";

const min = 1;
const max = 99;

export const OrderCardComponent: React.FC<{
  order: Omit<IOrderData, "_id" | "memberEmail" | "unscheduledProducts" | "defaultDeliveryPoint">;
  changeProdCount: (id: string, count: number) => void;
  setNote: (note: string) => void;
  origOrder?: Omit<IOrderData, "memberEmail" | "unscheduledProducts" | "defaultDeliveryPoint">;
  onEdit?: () => void;
  onDelete?: () => void;
  canEdit: boolean;
  actions: Array<{ primary?: boolean; disabled?: boolean; text: string; cb: () => void } | undefined>;
}> = ({ order, changeProdCount, setNote, onEdit, origOrder, onDelete, canEdit, actions }) => {
  const { t } = useTranslation();

  const [season] = useContext(SeasonContext);
  const productList = useQuery<{
    products: Array<IProductData>;
  }>(productsQuery, { variables: { season } });

  const commonStyles = useCommonStyles();
  const classes = useUserOrderListStyles();

  const sumPrice = order.products.reduce((acc, prod) => {
    const product = productList.data && productList.data.products.find((p) => p._id === prod.productId);
    return acc + prod.count * (product?.priceHUF || 0);
  }, 0);
  return (
    <Card>
      <CardHeader
        title={`${t("orders.card.titleStart")} ${new Date(order.createdDate).toLocaleDateString()}`}
        subheader={t(`orders.card.${order.state}`)}
        action={
          <>
            {onEdit && (
              <IconButton onClick={() => onEdit()}>
                <Edit />
              </IconButton>
            )}

            {onDelete && (
              <IconButton onClick={() => onDelete()}>
                <Delete />
              </IconButton>
            )}
          </>
        }
      />
      <CardActions>
        {actions.map(
          (a) =>
            a && (
              <Button
                variant={a.primary ? "contained" : "outlined"}
                color={a.primary ? "primary" : "inherit"}
                onClick={() => a.cb()}
                disabled={a.disabled}>
                {a.text}
              </Button>
            )
        )}
      </CardActions>

      <CardContent>
        {(canEdit || order.userNote) && (
          <TextField
            label={t("orders.card.noteLabel")}
            value={order.userNote}
            fullWidth={true}
            disabled={!canEdit}
            onChange={(ev) => setNote(ev.currentTarget.value)}
          />
        )}
        {!order.products || order.products.length === 0 ? (
          <Typography variant="h6">{canEdit ? t("orders.card.emptyEdit") : t("orders.card.empty")} </Typography>
        ) : (
          <List>
            {order.products.map((prod, ind) => {
              const p = (productList.data && productList.data.products.find((p) => p._id === prod.productId)) || {
                displayName: t("errors.unknownProduct"),
                description: t("errors.unknownProduct"),
                remainingStock: Number.POSITIVE_INFINITY,
                unitPrice: 0,
              };
              const origCount = origOrder && origOrder.products.find((p) => p.productId === prod.productId);
              return (
                <ListItem key={`${prod.productId}_${ind}`}>
                  <ListItemText secondary={prod.unitPrice && `${prod.count}x${prod.unitPrice} ft`}>
                    {p.displayName}
                    <ImmediateTooltipComponent title={p.description}>
                      <InfoOutlined fontSize="small" className={commonStyles.infoIcon} />
                    </ImmediateTooltipComponent>
                    {origOrder &&
                      p.remainingStock !== undefined &&
                      (prod.count - (origCount ? origCount.count : 0) > p.remainingStock) && (
                        <ImmediateTooltipComponent title={t("warnings.stock")}>
                          <WarningOutlined fontSize="small" />
                        </ImmediateTooltipComponent>
                      )}
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <TextField
                      type="number"
                      classes={{
                        root: classes.countField,
                      }}
                      inputProps={{
                        className: classes.countInput,
                        max,
                        min,
                      }}
                      size="small"
                      label={t("orders.card.countLabel")}
                      value={prod.count}
                      disabled={!canEdit}
                      onChange={(ev) => {
                        return changeProdCount(
                          prod.productId,
                          Math.min(
                            Math.max(ev.currentTarget.value ? Number.parseInt(ev.currentTarget.value) : min, min),
                            max
                          )
                        );
                      }}></TextField>
                    {canEdit && (
                      <IconButton onClick={() => changeProdCount(prod.productId, 0)}>
                        <Delete />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
            {order.products.length > 0 && sumPrice > 0 && (
              <ListItem>
                <ListItemText>
                  {(origOrder && order !== origOrder) || !order.offerPriceHUF
                    ? t("orders.card.proposedPrice", {
                        priceHUF: sumPrice,
                        mPriceHUF: Math.ceil(sumPrice / 12),
                      })
                    : t("orders.card.offerPrice", {
                        priceHUF: order.offerPriceHUF,
                        mPriceHUF: Math.ceil(order.offerPriceHUF / 12),
                      })}
                  {order.products.some(
                    (prod) =>
                      productList.data && !productList.data.products.find((p) => p._id === prod.productId)?.priceHUF
                  ) && (
                    <ImmediateTooltipComponent title={t("warnings.unsetPrice")}>
                      <WarningOutlined fontSize="default" className={commonStyles.infoIcon} />
                    </ImmediateTooltipComponent>
                  )}
                </ListItemText>
              </ListItem>
            )}

            {order.prepayments.map((prepayment) => (
              <ListItem key={prepayment._id}>
                <ListItemText>
                  {t("orders.card.prepayment", {
                    reason: prepayment.reason,
                    amount: prepayment.amount,
                  })}
                </ListItemText>
              </ListItem>
            ))}
            {order.invoices.map((invoice) => (
              <ListItem key={invoice._id}>
                <ListItemText>
                  {t(invoice.paid < invoice.price ? "orders.card.invoice.owed" : "orders.card.invoice.paid", {
                    issued: new Date(Date.parse(invoice.issued)).toLocaleDateString(),
                    owed: invoice.price - invoice.paid,
                    price: invoice.price,
                    id: invoice.id,
                  })}
                </ListItemText>
              </ListItem>
            ))}

            {Array.from(order.deliveries).sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((del) => (
              <ListItem key={`${del.date}`}>
                <ListItemText
                  primary={t("orders.card.delivery", {
                    date: new Date(Date.parse(del.date)).toLocaleDateString(),
                    stopName: del.stopName,
                  })}
                  secondary={zipWithCount(
                    del.productIds.map((pid) => productList.data?.products.find((p) => p._id === pid)?.displayName)
                  )
                    .map(([c, n]) => `${c} x ${n}`)
                    .join(", ")}
                />
                <ListItemAvatar>
                  {del.state === DeliveryState.NoShow ? (
                    <Error />
                  ) : del.state === DeliveryState.Ok ? (
                    <Done />
                  ) : del.state === DeliveryState.ReScheduled ? (
                    <Schedule />
                  ) : (
                    <LocalShipping />
                  )}
                </ListItemAvatar>
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};
