import React from "react";

import { Modal, Typography, Paper } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import { useModalStyles } from "./modal.style";
import { Link } from "react-router-dom";

export const ErrorModal: React.FC<{ error?: string; onClose?: () => void; url?: string }> = ({
  error,
  onClose,
  url,
}) => {
  const classes = useModalStyles();
  const { t } = useTranslation();

  return (
    <Modal open={!!error} className={classes.loadingModal} onEscapeKeyDown={onClose} onBackdropClick={onClose}>
      <Paper className={classes.modalPaper}>
        <Typography component="h3" variant="h4">
          {t(`errors.modal.${error}.title`)}
        </Typography>
        <Typography variant="body1">{t(`errors.modal.${error}.desc`)}</Typography>
        {url && <Link to={url}>{t(`errors.modal.${error}.link`)}</Link>}
      </Paper>
    </Modal>
  );
};
