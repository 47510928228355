import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContent: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "center",
      padding: theme.spacing(2),
      flexGrow: 1,
      flexShrink: 0,
    },
    loadingModal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& > *": {
        outline: "none",
      },
    },
    infoIcon: {
      marginBottom: "-0.2em",
      marginLeft: "1em",
    },
    plainLink: {
      textDecoration: "none",
      color: "inherit",
    },
  })
);
