import React, { useState } from "react";

import { TextField, Typography, Container, InputAdornment, CircularProgress } from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useDebouncedCallback } from "use-debounce";

import { LoadingModal } from "../modals/loadingModal.component";
import { DefaultDeliveryPointModal } from "../modals/defaultDeliveryModal.component";
import { BillingInfoFormComponent } from "./billingInfoForm.component";

import { meQuery, IMemberData } from "../queries";

import { useCommonStyles } from "../common.style";
import { useMemberDataStyles } from "./memberData.style";
import { gql } from "apollo-boost";
import { Check } from "@material-ui/icons";

export const MemberDataComponent: React.FC = () => {
  const commonStyles = useCommonStyles();
  const classes = useMemberDataStyles();

  const { t } = useTranslation();

  const meState = useQuery<{ me: IMemberData }>(meQuery);
  const [updateMe] = useMutation(gql`
    mutation updateMe($fullName: String, $phoneNumber: String) {
      updateMe(update: { fullName: $fullName, phoneNumber: $phoneNumber }) {
        _id
        fullName
        phoneNumber
      }
    }
  `);

  const [deliveryPointModalOpen, setDeliveryPointModalOpen] = useState(false);

  function useSavedField<T>(fieldName: string, defVal: T): [T, (v: T) => void] {
    const [field, setter] = useState<T>(defVal);
    const [save] = useDebouncedCallback((val: T) => {
      updateMe({
        variables: {
          [fieldName]: val,
        },
      });
    }, 1000);

    return [
      field,
      (val: T) => {
        setter((ov) => {
          if ((typeof ov === "string" ? ov.trim() : ov) !== (typeof val === "string" ? val.trim() : val)) {
            save(val);
          }
          return val;
        });
      },
    ];
  }

  const [name, setName] = useSavedField<string | undefined>("fullName", undefined);
  const [phoneNumber, setPhoneNumber] = useSavedField<string | undefined>("phoneNumber", undefined);

  if (meState.loading || !meState.data || !meState.data.me) {
    return (
      <Container component="main" maxWidth="xl" className={commonStyles.mainContent}>
        <LoadingModal open={meState.loading} />;
      </Container>
    );
  }

  const me = meState.data.me;

  return (
    <Container component="main" maxWidth="xl" className={clsx(commonStyles.mainContent, classes.page)}>
      <DefaultDeliveryPointModal
        open={deliveryPointModalOpen}
        onClose={() => setDeliveryPointModalOpen(false)}
        onUpdate={() => setDeliveryPointModalOpen(false)}
      />
      <Typography variant="h2">{t("memberData.title")}</Typography>
      <form
        className={classes.form}
        onSubmit={(ev) => {
          ev.preventDefault();
        }}>
        <TextField
          label={t("memberData.name")}
          value={name === undefined ? me.fullName : name}
          required={true}
          onChange={(ev) => setName(ev.currentTarget.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!name || me.fullName.trim() === name.trim() ? <Check /> : <CircularProgress size="1em" />}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={t("memberData.phoneNumber")}
          value={phoneNumber === undefined ? me.phoneNumber : phoneNumber}
          onChange={(ev) => setPhoneNumber(ev.currentTarget.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!phoneNumber || me.phoneNumber.trim() === phoneNumber.trim() ? (
                  <Check />
                ) : (
                  <CircularProgress size="1em" />
                )}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={t("memberData.deliveryPoint")}
          value={me.defaultDeliveryPoint && me.defaultDeliveryPoint.displayName}
          onClick={() => setDeliveryPointModalOpen(true)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Check />
              </InputAdornment>
            ),
          }}
        />
      </form>
      <BillingInfoFormComponent />
    </Container>
  );
};
