import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useProductListStyles = makeStyles((theme: Theme) =>
  createStyles({
    productForm: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      "& div:first-child": {
        flexGrow: 1,
      },
    },
    sortDropDown: {
      float: "right",
      marginTop: "1em",
    },
  })
);
