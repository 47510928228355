import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import clsx from "clsx";

import {
  Typography,
  Container,
  List,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
  ListItem,
  Fab,
  TextField,
  Paper,
} from "@material-ui/core";
import { Save, Edit, Add } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { useTranslation } from "react-i18next";

import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";

import { useCommonStyles } from "../common.style";
import { useMOTDStyles } from "./pollEditor.style";

interface IPoll {
  _id: string;
  title: string;
  description: string;
  fromDate: string;
  untilDate: string;
  maxChoice: string;
  options: Array<{
    _id: string;
    displayName: string;
    description: string;
    votes: string[];
  }>;
}

export const PollEditorComponent: React.FC = () => {
  const { t } = useTranslation();

  const pollsQuery = gql`
    query polls {
      polls {
        _id
        title
        description
        fromDate
        untilDate
        maxChoice
        options {
          _id
          displayName
          description
          votes
        }
      }
    }
  `;

  const polls = useQuery<{
    polls: Array<IPoll>;
  }>(pollsQuery);

  const [createPoll] = useMutation<{ createPoll: IPoll }>(
    gql`
      mutation createPoll(
        $title: String!
        $description: String!
        $fromDate: String!
        $untilDate: String!
        $maxChoice: Int!
        $options: [PollOptionInput!]!
      ) {
        createPoll(
          title: $title
          description: $description
          fromDate: $fromDate
          untilDate: $untilDate
          maxChoice: $maxChoice
          options: $options
        ) {
          _id
          title
          description
          fromDate
          untilDate
          maxChoice
          options {
            _id
            displayName
            description
            votes
          }
        }
      }
    `,
    {
      update(cache, { data }) {
        const cachedData = cache.readQuery<{
          polls: Array<IPoll>;
        }>({ query: pollsQuery })!;

        if (data) {
          cache.writeQuery({
            query: pollsQuery,
            data: { polls: cachedData.polls.concat([data.createPoll]) },
          });
        }
      },
    }
  );

  const [updatePoll] = useMutation<{ updatePoll: IPoll }>(
    gql`
      mutation updatePoll(
        $id: String!
        $title: String!
        $description: String!
        $fromDate: String!
        $untilDate: String!
        $maxChoice: Int!
        $options: [PollOptionInput!]!
      ) {
        createPoll(
          id: $id
          title: $title
          description: $description
          fromDate: $fromDate
          untilDate: $untilDate
          maxChoice: $maxChoice
          options: $options
        ) {
          _id
          title
          description
          fromDate
          untilDate
          maxChoice
          options {
            _id
            displayName
            description
            votes
          }
        }
      }
    `
  );

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const [editedId, setEditedId] = useState<string | undefined>(undefined);
  const [title, setTitle] = useState("");
  const [description, setDesc] = useState("");
  const [fromDate, setFrom] = useState<Date>(new Date());
  const [untilDate, setUntil] = useState<Date>(new Date());

  const [options, setOptions] = useState<
    Array<{ _id: string; displayName: string; description: string; votes: string[] }>
  >([]);

  const [inited, setInited] = useState(false);

  useEffect(() => {
    if (!inited && !polls.loading && polls.data) {
      if (polls.data.polls.length === 0) {
        setEditedId("new");
      }
      setInited(true);
    }
  }, [inited, polls]);

  const commonStyles = useCommonStyles();
  const classes = useMOTDStyles();

  async function save() {
    if (editedId === "new") {
      await createPoll({
        variables: {
          title,
          description,
          options,
          fromDate,
          untilDate,
          maxChoice: 1,
        },
      });
    } else {
      await updatePoll({
        variables: {
          id: editedId,
          title,
          description,
          options,
          fromDate,
          untilDate,
          maxChoice: 1,
        },
      });
    }
    setEditedId(undefined);
    setTitle("");
    setDesc("");
    setOptions([]);
    setFrom(new Date());
    setUntil(new Date());
  }

  async function select(poll: IPoll) {
    setFrom(new Date(Date.parse(poll.fromDate)));
    setUntil(new Date(Date.parse(poll.untilDate)));
    setTitle(poll.title);
    setDesc(poll.description);
    setOptions(poll.options);
    setEditedId(poll._id);
  }

  return (
    <Container component="main" maxWidth="xl" className={clsx(commonStyles.mainContent, classes.page)}>
      <Typography variant="h3" component="h2">
        {t("motd.editor.title")}
        <IconButton onClick={() => setEditedId("new")} color="primary">
          <Add />
        </IconButton>
      </Typography>
      {editedId ? (
        <>
          <KeyboardDatePicker value={fromDate} onChange={(d) => d && setFrom(d)} />
          <KeyboardDatePicker value={untilDate} onChange={(d) => d && setUntil(d)} />
          <TextField value={title} onChange={(e) => setTitle(e.target.value)} fullWidth={true} />
          <ReactMde
            value={description}
            onChange={setDesc}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown source={markdown} />)}
          />
          <section>
            {options.map((opt, ind) => (
              <Paper key={`poll_opt_${ind}`}>
                <TextField
                  value={opt.displayName}
                  onChange={(e) => {
                    const displayName = e.target.value;
                    setOptions((a) => a.map((c, i) => (i === ind ? { ...c, displayName } : c)));
                  }}
                />
                <TextField
                  value={opt.description}
                  fullWidth={true}
                  onChange={(e) => {
                    const description = e.target.value;
                    return setOptions((a) => a.map((c, i) => (i === ind ? { ...c, description } : c)));
                  }}
                />
                <Typography>
                  {opt.votes.length}: {opt.votes.join(", ")}
                </Typography>
              </Paper>
            ))}
            <IconButton
              onClick={() =>
                setOptions((a) => a.concat([{ displayName: "", description: "", _id: "new", votes: [] }]))
              }>
              <Add />
            </IconButton>
          </section>
          <Fab onClick={() => save()}>
            <Save />
          </Fab>
        </>
      ) : (
        <>
          <List>
            {polls.data &&
              polls.data.polls.map((m) => (
                <ListItem>
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => select(m)}>
                      <Edit />
                    </IconButton>
                  </ListItemSecondaryAction>
                  <ListItemText primary={m.title} secondary={`${m.fromDate}-${m.untilDate}`} />
                </ListItem>
              ))}
          </List>
        </>
      )}
    </Container>
  );
};
