import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStopViewStyles = makeStyles((theme: Theme) =>
  createStyles({
    stopView: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(400px, auto))",
      gridGap: theme.spacing(2),
    },
    deliveryCard: {},
  })
);
