import React, { useState } from "react";
import { CircularProgress, Modal, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";

import { useModalStyles } from "./modal.style";
import { useTranslation } from "react-i18next";

export const ApprovalDialog: React.FC<{
  open: boolean;
  titleKey?: string;
  contentKey?: string;
  actionKey?: string;
  continueAction: () => Promise<void>;
  cancelAction: () => void;
  isDestructive?: boolean;
}> = ({ open, titleKey, contentKey, actionKey, isDestructive = true, continueAction, cancelAction }) => {
  const classes = useModalStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={open} className={classes.loadingModal} onClose={cancelAction}>
      <DialogTitle>{t(titleKey || "modal.approval.defaultTitle")}</DialogTitle>
      {loading && (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      )}
      {!loading && contentKey && <DialogContent>{t(contentKey)}</DialogContent>}
      <DialogActions>
        <Button
          className={isDestructive ? classes.destructiveAction : ""}
          color="primary"
          onClick={() => {
            setLoading(true);
            continueAction().finally(() => setLoading(false));
          }}>
          {t(actionKey || "modal.approval.defaultContinue")}
        </Button>
        <Button onClick={cancelAction}>{t("modal.approval.cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
};
