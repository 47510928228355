import React from "react";

import { CssBaseline } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";

import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient, { InMemoryCache, defaultDataIdFromObject } from "apollo-boost";
import { toIdValue } from "apollo-utilities";
import MUICookieConsent from "material-ui-cookie-consent";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import "react-mde/lib/styles/css/react-mde-all.css";

import { RouterComponent } from "./router.component";
import { SeasonProvider } from "./season/season.context";

const client: ApolloClient<InMemoryCache> = new ApolloClient({
  uri: "https://tancoskert-api.lengyel.tech/api/graphql",
  credentials: "include",
  cacheRedirects: {
    Query: {
      order: (_, args) => toIdValue(defaultDataIdFromObject({ __typename: "Order", id: args.id })!),
      deliveryTour: (_, args) => toIdValue(defaultDataIdFromObject({ __typename: "Tour", id: args.id })!),
      deliveryStop: (_, args) => toIdValue(defaultDataIdFromObject({ __typename: "Stop", id: args.id })!),
    },
  },
});

const App = () => {
  return (
    <BrowserRouter>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MUICookieConsent cookieName="tancoskertCookieConsent" message="This site uses cookies.... bla bla..." />
        <ApolloProvider client={client}>
          <CssBaseline />
          <SeasonProvider>
            <RouterComponent />
          </SeasonProvider>
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    </BrowserRouter>
  );
};

export default App;
