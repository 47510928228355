import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  CircularProgress,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  TextField,
  Modal,
  Button,
  Paper,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Delete, Edit, OpenInBrowser } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { gql } from "apollo-boost";
import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";

import {
  allToursQuery, IDeliveryPointData, ITourData,
  productsQuery,
  IProductData,
} from "../queries";

import { SeasonContext } from "../season/season.context";

import { count, sum, zipWithCount } from "../utils";

import { useCommonStyles } from "../common.style";
import { useProductListStyles } from "./deliveryTourList.style";
import { useModalStyles } from "../modals/modal.style";
import { ApprovalDialog } from "../modals/approvalDialog.component";

export const DeliveryTourList: React.FC = () => {
  const tours = useQuery<{
    deliveryTours: Array<ITourData>;
  }>(allToursQuery);

  const [season] = useContext(SeasonContext);
  const productList = useQuery<{
    products: Array<IProductData>;
  }>(productsQuery, { variables: { season } });

  const [addTour, addState] = useMutation<{ addDeliveryTour: ITourData }>(
    gql`
      mutation addDeliveryTour($title: String!, $date: String!, $pointIds: [String!]) {
        addDeliveryTour(title: $title, date: $date, pointIds: $pointIds) {
          _id
          title
          date
          stops {
            _id
            deliveryPoint {
              _id
              displayName
            }
            deliveries {
              _id
              email
              productIds
            }
          }
        }
      }
    `,
    {
      update(cache, { data }) {
        const cachedData = cache.readQuery<{
          deliveryTours: Array<ITourData>;
        }>({ query: allToursQuery })!;

        if (data) {
          cache.writeQuery({
            query: allToursQuery,
            data: { deliveryTours: cachedData.deliveryTours.concat([data.addDeliveryTour]) },
          });
        }
      },
    }
  );

  const [updateDeliveryTour, updateState] = useMutation<{ updateDeliveryTour: ITourData }>(
    gql`
      mutation updateDeliveryTour($id: String!, $title: String!, $date: String!) {
        updateDeliveryTour(id: $id, title: $title, date: $date) {
          _id
          title
          date
          stops {
            _id
            deliveryPoint {
              _id
              displayName
            }
            deliveries {
              _id
              email
              productIds
            }
          }
        }
      }
    `
  );

  const [deleteTour] = useMutation<{ deleteTour: IDeliveryPointData }>(
    gql`
      mutation deleteDeliveryTour($id: String!) {
        deleteDeliveryTour(id: $id) {
          _id
        }
      }
    `,
    {
      refetchQueries: [{ query: allToursQuery }],
    }
  );

  const [deleteIntent, setDeleteIntent] = useState<string | undefined>();

  const [id, setId] = useState<string | undefined>();
  const [title, setTitle] = useState<string>("");
  const [date, setDate] = useState<Date | null>();
  function startAdding(t?: ITourData) {
    if (t) {
      setTitle(t.title);
      setDate(new Date(Date.parse(t.date)));
      setId(t._id);
    } else {
      setTitle("");
      setDate(null);
      setId("new");
    }
  }
  const [orderBy, setOrderBy] = useState("dateDesc");

  const classes = useProductListStyles();
  const modalClasses = useModalStyles();

  const [orderedTours, setOrderedTours] = useState<ITourData[] | undefined>(undefined);

  useEffect(() => {
    const deliveryTours = tours?.data?.deliveryTours;
    if (deliveryTours) {
      switch (orderBy) {
        case "dateAsc":
          setOrderedTours(Array.from(deliveryTours).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()));
          break;
        case "dateDesc":
          setOrderedTours(Array.from(deliveryTours).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()));
          break;
        default:
          setOrderedTours(deliveryTours);
      }
    }
  }, [setOrderedTours, tours?.data?.deliveryTours, orderBy]);

  return (
    <Container component="main" maxWidth="xl" className={useCommonStyles().mainContent}>
      <Typography variant="h3" component="h2">
        Kiszállítások
        <IconButton onClick={() => startAdding()} color="primary">
          <AddIcon />
        </IconButton>
        <Select className={classes.sortDropDown} value={orderBy} label="Rendezés" onChange={(ev) => setOrderBy(ev.target.value as string)}>
          <MenuItem value={"dateAsc"}>Dátum &uarr;</MenuItem>
          <MenuItem value={"dateDesc"}>Dátum &darr;</MenuItem>
        </Select>
      </Typography>
      <ApprovalDialog
        titleKey="tourList.deleteTour.title"
        contentKey="tourList.deleteTour.content"
        actionKey="tourList.deleteTour.doDelete"
        cancelAction={() => setDeleteIntent(undefined)}
        continueAction={() => deleteTour({ variables: { id: deleteIntent } }).then(() => setDeleteIntent(undefined))}
        open={!!deleteIntent}
        isDestructive={true}
      />
      <Modal
        open={!!id}
        onBackdropClick={() => setId(undefined)}
        onClose={() => setId(undefined)}
        className={modalClasses.loadingModal}>
        {addState.loading || updateState.loading ? (
          <CircularProgress />
        ) : (
          <Paper component="form" className={clsx(modalClasses.loadingModal, classes.productForm)}>
            <TextField value={title} onChange={(ev) => setTitle(ev.target.value)} label={"Cim"} />
            <KeyboardDatePicker value={date} onChange={(d) => setDate(d)} />

            <Button
              color="primary"
              variant="contained"
              disabled={!date}
              onClick={() => {
                if (date && title) {
                  if (id === "new") {
                    addTour({
                      variables: {
                        title,
                        date: date.toISOString(),
                        pointIds: [],
                      },
                    }).then(() => setId(undefined));
                  } else {
                    updateDeliveryTour({
                      variables: {
                        id,
                        title,
                        date: date.toISOString(),
                      },
                    }).then(() => setId(undefined));
                  }
                }
              }}>
              Mentes
            </Button>
          </Paper>
        )}
      </Modal>
      <List>
        {tours.loading && <CircularProgress />}
        {tours.error && tours.error.message}
        {orderedTours &&
          orderedTours.map((t) => (
            <ListItem key={t._id}>
              <ListItemText secondary={
                <>{t.stops.map((s) => s.deliveryPoint.displayName).join(", ")}
                  <br />
                  {productList.data?.products.map(p => [
                    p.displayName,
                    sum(t.stops, s => sum(s.deliveries,
                      d => count(d.productIds, pid => pid === p._id)))
                  ]).filter(([_, count]) => count !== 0).map(e => e.join('x')).join(', ')}
                </>
              }>
                {t.title}  ({new Date(t.date).toLocaleDateString()})
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton onClick={() => startAdding(t)}>
                  <Edit />
                </IconButton>
                <IconButton href={`/kiszallitasok/${t._id}`}>
                  <OpenInBrowser />
                </IconButton>
                <IconButton onClick={() => setDeleteIntent(t._id)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
    </Container>
  );
};
