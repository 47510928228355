import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import clsx from "clsx";

import {
  Typography,
  Container,
  List,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
  ListItem,
  Fab,
} from "@material-ui/core";
import { Save, Edit, Add } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { useTranslation } from "react-i18next";

import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";

import { useCommonStyles } from "../common.style";
import { useMOTDStyles } from "./motd.style";

interface IMOTD {
  _id: string;
  markdown: string;
  fromDate: string;
  untilDate: string;
}

export const MOTDEditorComponent: React.FC = () => {
  const { t } = useTranslation();

  const motdQuery = gql`
    query motds {
      motds {
        _id
        markdown
        fromDate
        untilDate
      }
    }
  `;

  const motds = useQuery<{
    motds: Array<IMOTD>;
  }>(motdQuery);

  const [setMOTD] = useMutation<{ setMOTD: IMOTD }>(
    gql`
      mutation setMOTD($md: String!, $fromDate: String!, $untilDate: String!) {
        setMOTD(markdown: $md, fromDate: $fromDate, untilDate: $untilDate) {
          _id
          markdown
          fromDate
          untilDate
        }
      }
    `,
    {
      update(cache, { data }) {
        const cachedData = cache.readQuery<{
          motds: Array<IMOTD>;
        }>({ query: motdQuery })!;

        if (data) {
          cache.writeQuery({
            query: motdQuery,
            data: { motds: cachedData.motds.concat([data.setMOTD]) },
          });
        }
      },
    }
  );

  const [updateMOTD] = useMutation<{ setMOTD: IMOTD }>(
    gql`
      mutation updateMOTD($id: String!, $md: String!, $fromDate: String!, $untilDate: String!) {
        updateMOTD(id: $id, markdown: $md, fromDate: $fromDate, untilDate: $untilDate) {
          _id
          markdown
          fromDate
          untilDate
        }
      }
    `
  );

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const [editedId, setEditedId] = useState<string | undefined>(undefined);
  const [md, setMd] = useState("");
  const [fromDate, setFrom] = useState<Date>(new Date());
  const [untilDate, setUntil] = useState<Date>(new Date());

  const [inited, setInited] = useState(false);

  useEffect(() => {
    if (!inited && !motds.loading && motds.data) {
      if (motds.data.motds.length === 0) {
        setEditedId("new");
      }
      setInited(true);
    }
  }, [inited, motds]);

  const commonStyles = useCommonStyles();
  const classes = useMOTDStyles();

  async function save() {
    if (editedId === "new") {
      await setMOTD({
        variables: {
          md,
          fromDate,
          untilDate,
        },
      });
    } else {
      await updateMOTD({
        variables: {
          id: editedId,
          md,
          fromDate,
          untilDate,
        },
      });
    }
    setEditedId(undefined);
    setMd("");
    setFrom(new Date());
    setUntil(new Date());
  }

  async function select(motd: IMOTD) {
    setFrom(new Date(Date.parse(motd.fromDate)));
    setUntil(new Date(Date.parse(motd.untilDate)));
    setMd(motd.markdown);
    setEditedId(motd._id);
  }

  return (
    <Container component="main" maxWidth="xl" className={clsx(commonStyles.mainContent, classes.page)}>
      <Typography variant="h3" component="h2">
        {t("motd.editor.title")}
        <IconButton onClick={() => setEditedId("new")} color="primary">
          <Add />
        </IconButton>
      </Typography>
      {editedId ? (
        <>
          <KeyboardDatePicker value={fromDate} onChange={d => d && setFrom(d)} />
          <KeyboardDatePicker value={untilDate} onChange={d => d && setUntil(d)} />
          <ReactMde
            value={md}
            onChange={setMd}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={markdown => Promise.resolve(<ReactMarkdown source={markdown} />)}
          />
          <Fab onClick={() => save()}>
            <Save />
          </Fab>
        </>
      ) : (
        <>
          <List>
            {motds.data &&
              motds.data.motds.map(m => (
                <ListItem>
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => select(m)}>
                      <Edit />
                    </IconButton>
                  </ListItemSecondaryAction>
                  <ListItemText primary={m.markdown.substr(0, 52)} secondary={`${m.fromDate}-${m.untilDate}`} />
                </ListItem>
              ))}
          </List>
        </>
      )}
    </Container>
  );
};
