import React from "react";
import { createContext, useState } from "react";

export function getCurrentSeason() {
  if (localStorage.getItem("season")) {
    return Number.parseInt(localStorage.getItem("season")!);
  }

  const date = new Date();
  const year = date.getFullYear() - 2000;
  if (date.getMonth() >= 2) {
    return year;
  }
  return year - 1;
}

export const SeasonContext = createContext<[number, (season: number) => void]>([getCurrentSeason(), (season) => {}]);

export const SeasonProvider: React.FC = ({ children }) => {
  const [season, setSeason] = useState(getCurrentSeason());

  return <SeasonContext.Provider value={[season, setSeason]}>{children}</SeasonContext.Provider>;
};
