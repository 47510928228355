import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useProductListStyles = makeStyles((theme: Theme) =>
  createStyles({
    productForm: {
      paddingLeft: theme.spacing(2),
      display: "flex",
      alignItems: "flex-end",
      marginBottom: theme.spacing(1),
      "& div:first-child": {
        flexGrow: 1,
      },
    },
  })
);
