import React from "react";
import { useQuery } from "@apollo/react-hooks";
import clsx from "clsx";

import {
  CircularProgress,
  Container,
  Divider,
  Typography,
  ListItem,
  ListItemText,
  List,
  ListItemAvatar,
} from "@material-ui/core";

import { Redirect } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import { useCommonStyles } from "../common.style";
import { useMOTDStyles } from "./motd.style";
import { meQuery, IMemberData } from "../queries";
import { Check, CheckOutlined } from "@material-ui/icons";

export const MOTDComponent: React.FC = () => {
  const { loading, data } = useQuery<{
    me: IMemberData;
    motd: string;
    currentPolls: Array<{
      title: string;
      description: string;
      options: Array<{ displayName: string; description: string; votes: string[] }>;
    }>;
  }>(meQuery);

  const commonStyles = useCommonStyles();
  const classes = useMOTDStyles();

  return (
    <Container component="main" maxWidth="xl" className={clsx(commonStyles.mainContent, classes.page)}>
      {loading || !data ? (
        <CircularProgress />
      ) : data.motd || (data.currentPolls && data.currentPolls.length > 0) ? (
        <>
          <ReactMarkdown
            skipHtml={true}
            source={data && data.motd}
            renderers={{
              thematicBreak: () => <Divider />,
              heading: (x, v) => {
                return (
                  <Typography variant={`h${x.level + 3}` as any} className={classes.motdHeader}>
                    {x.children}
                  </Typography>
                );
              },
              paragraph: (x) => (
                <Typography variant="body1" className={classes.motdPara}>
                  {x.children}
                </Typography>
              ),
            }}
          />
          {data.currentPolls &&
            data.currentPolls.map((p) => (
              <>
                <Typography variant="h6">{p.title}</Typography>
                <Typography variant="body1">{p.description}</Typography>
                <List>
                  {p.options.map((o) => (
                    <ListItem>
                      <ListItemAvatar>{o.votes.length > 0 ? <Check /> : <CheckOutlined />}</ListItemAvatar>
                      <ListItemText primary={o.displayName} secondary={o.description} />
                    </ListItem>
                  ))}
                </List>
              </>
            ))}
        </>
      ) : (
        <Redirect to={data.me.isAdmin ? "/tagok" : "/elojegyzes"} />
      )}
    </Container>
  );
};
