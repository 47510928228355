import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useUserOrderListStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      width: "100%",
    },
    memberCard: {
      marginBottom: theme.spacing(2),
    },
  })
);
