import React, { useContext, useState } from "react";
import clsx from "clsx";

import { useQuery } from "@apollo/react-hooks";
import { Card, CardHeader, CardContent, Typography, List, ListItem, Container, ListItemText } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useCommonStyles } from "../common.style";
import { IOrderData, IProductData, myOrdersQuery, OrderState, productsQuery } from "../queries";
import { useMemberInvoicesStyles } from "./memberInvoices.style";
import { LoadingModal } from "../modals/loadingModal.component";
import { SeasonContext } from "../season/season.context";

export const MemberInvoicesComponent: React.FC = () => {
  const orderList = useQuery<{
    myOrders: Array<IOrderData>;
  }>(myOrdersQuery);

  const [season] = useContext(SeasonContext);

  const productList = useQuery<{
    products: Array<IProductData>;
  }>(productsQuery, { variables: { season } });

  const { t } = useTranslation();

  const commonStyles = useCommonStyles();
  const classes = useMemberInvoicesStyles();

  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  const orders = orderList.data?.myOrders;
  const products = productList.data?.products;

  if (!orders || !products) {
    return <LoadingModal open={true} />;
  }

  const seasons = Array.from(
    new Set<number>([...orders.map((o: IOrderData) => o.season), season])
  );

  return (
    <Container component="main" maxWidth="xl" className={clsx(commonStyles.mainContent, classes.page)}>
      <Typography variant="h3" component="h2">
        {t("memberInvoices.title")}
      </Typography>
      <section className={classes.dashboard}>
        {seasons.map((currSeason) => {
          const ordersInSeason = orders.filter(
            (o: IOrderData) => o.season === currSeason && o.state === OrderState.Accepted
          );

          const invoices = ordersInSeason.flatMap((o) =>
            o.invoices
              .filter((i) => i.price > 0 && !i.isReversed)
              .map((i) => ({
                id: i.id,
                price: i.price,
                paid: i.paid === i.price,
                displayName:
                  (i.paid === i.price ? "Számla" : "Díjbekérő") +
                  `: ${i.id}` +
                  (i.issued ? `(${new Date(Date.parse(i.issued)).toLocaleDateString()})` : ""),
                issued: Date.parse(i.issued),
              }))
              .concat(
                o.prepayments.map((p) => ({
                  id: p._id,
                  displayName: p.reason,
                  price: p.amount,
                  paid: true,
                  issued: Date.now(),
                }))
              )
              .sort((a, b) => a.issued - b.issued)
          );

          return (
            (invoices.length > 0 || currSeason === season) && (
              <Card className={classes.dashboardCard}>
                <CardHeader title={`${currSeason}-${currSeason + 1} szezon:`} />
                <CardContent>
                  <List>
                    {invoices.map((invoice) => (
                      <ListItem key={invoice.id}>
                        <ListItemText
                          primary={`${invoice.displayName}: ${invoice.price}`}
                          secondary={invoice.paid ? "Befizetve" : "Hátralék"}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            )
          );
        })}
      </section>
    </Container>
  );
};
