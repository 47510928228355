export function zipWithCount(arr: Array<string | undefined>): Array<[number, string]> {
  return arr
    .filter((n) => !!n)
    .reduce((acc, curr) => {
      const oldIt = acc.find(([c, n]) => n === curr) || [0, curr];
      return acc.filter((c) => c !== oldIt).concat([[oldIt[0] + 1, curr!]]);
    }, [] as Array<[number, string]>);
}

// type MatchingOnly<T, V> = { [K in keyof T]: T[K] extends V ? K : never };
// export function sumProp<T>(arr: Array<MatchingOnly<T, number>>, key: keyof MatchingOnly<T, number>) {
//   return arr.filter((n) => !!n).reduce((acc, curr) => acc + curr[key], 0);
// }

export function sum<T>(arr: Array<T>, selector: (item: T) => number | undefined) {
  return arr.reduce((acc, curr) => acc + (selector(curr) || 0), 0);
}

export function count<T>(arr: Array<T>, selector: (item: T) => boolean) {
  return arr.reduce((acc, curr) => acc + (selector(curr) ? 1 : 0), 0);
}
