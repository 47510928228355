import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useMemberDeliveriesStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      width: "100%",
    },
    deliveryList: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    }
  })
);
