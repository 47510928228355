import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStopEditorStyles = makeStyles((theme: Theme) =>
  createStyles({
    stopView: {
      display: "flex",
      flexDirection: "row",
    },
    stopColumn: {
      minWidth: "40em",
      flexGrow: 1,
      "&:first-of-type": {
        marginRight: theme.spacing(4),
      },
      "& > div": {
        marginBottom: theme.spacing(2),
      },
    },
  })
);
