import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useMOTDStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      width: "100%",
    },
    saveFab: {
      position: "fixed",
      bottom: 64,
      right: 64,
    },
    motdHeader: {
      marginTop: theme.spacing(1),
    },
    motdPara: {
      marginTop: theme.spacing(1),
    },
  })
);
