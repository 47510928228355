import React, { useState, useEffect } from "react";
import {
  Modal,
  TextField,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
} from "@material-ui/core";

import { useModalStyles } from "./modal.style";
import { IOrderData, OrderState, allOrdersQuery } from "../queries";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { DeleteForever } from "@material-ui/icons";

export const PrepaymentModal: React.FC<{ order?: IOrderData; onSuccess: () => void }> = ({ order, onSuccess }) => {
  const classes = useModalStyles();
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState(0);

  const [recordPrepayment, recordPrepaymentState] = useMutation(
    gql`
      mutation recordPrepayment($id: String!, $reason: String!, $amount: Int!) {
        recordPrepayment(id: $id, reason: $reason, amount: $amount) {
          _id
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: allOrdersQuery,
          variables: {
            allowedStates: [
              OrderState.Created,
              OrderState.Filed,
              OrderState.OfferSent,
              OrderState.Accepted,
              OrderState.Completed,
            ],
          },
        },
      ],
    }
  );

  const [deletePrepayment, deletePrepaymentState] = useMutation(
    gql`
      mutation deletePrepayment($id: String!) {
        deletePrepayment(id: $id)
      }
    `,
    {
      refetchQueries: [
        {
          query: allOrdersQuery,
          variables: {
            allowedStates: [
              OrderState.Created,
              OrderState.Filed,
              OrderState.OfferSent,
              OrderState.Accepted,
              OrderState.Completed,
            ],
          },
        },
      ],
    }
  );

  function submit() {
    if (order && !recordPrepaymentState.loading) {
      recordPrepayment({
        variables: {
          id: order._id,
          reason,
          amount,
        },
      }).then(() => {
        setReason("");
        setAmount(0);
      });
    }
  }

  useEffect(() => {
    setReason("");
    setAmount(0);
  }, [order]);

  return (
    <Modal open={!!order} className={classes.loadingModal} onClose={() => onSuccess()}>
      <Paper className={classes.modalPaper}>
        <Table>
          <TableHead>
            <TableCell>Ok</TableCell>
            <TableCell>Ar</TableCell>
          </TableHead>
          <TableBody>
            {order &&
              order.prepayments.map((p) => (
                <TableRow>
                  <TableCell>{p.reason}</TableCell>
                  <TableCell>{p.amount}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => deletePrepayment({ variables: { id: p._id } })}>
                      {deletePrepaymentState.loading ? <CircularProgress /> : <DeleteForever />}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}

            <TableRow>
              <TableCell>
                <TextField
                  onChange={(ev) => setReason(ev.currentTarget.value)}
                  value={reason}
                  placeholder="Ok"
                  fullWidth={true}
                />
              </TableCell>
              <TableCell>
                <TextField
                  onChange={(ev) => setAmount(Number.parseInt(ev.currentTarget.value))}
                  value={amount}
                  placeholder="Ertek (ft)"
                  type="number"
                  fullWidth={true}
                />
              </TableCell>
              <TableCell>
                <Button type="submit" variant="contained" color="primary" onClick={() => submit()}>
                  {recordPrepaymentState.loading ? <CircularProgress /> : "Mentes"}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Modal>
  );
};
