import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useMemberDeliveriesStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      width: "100%",
    },
    dashboard: {
      display: 'flex',
      flexWrap: "wrap",
      flexDirection: "row",
      gap: "1rem",
    },
    dashboardCard: {
      flex: "1 1 min(60vw, 50ch)"
    },
    negativeBalance: {
      color: theme.palette.error.main,
    },
    nonNegBalance: {
      color: theme.palette.success.main,
    },
    cardActions: {
      flexWrap: "wrap",

      "& > p": {
        width: "100%",
      }
    }
  })
);
