import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useMemberDataStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      width: "100%",
    },
    editModal: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
      padding: theme.spacing(4),
      "& > .MuiPaper-root": {
        width: 600,
        maxWidth: "40vw",
      },
    },
    form: {
      maxWidth: "40em",
      display: "flex",
      flexDirection: "column",
      "& > *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);
