import React from "react";

import {
  CircularProgress,
  Modal,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import { useQuery, useMutation } from "@apollo/react-hooks";

import { deliveryPointsQuery, IDeliveryPointData, meQuery, IMemberData } from "../queries";
import { useModalStyles } from "./modal.style";
import { gql } from "apollo-boost";

export const DefaultDeliveryPointModal: React.FC<{ open: boolean; onClose?: () => void; onUpdate?: () => void }> = ({
  open,
  onClose,
  onUpdate,
}) => {
  const classes = useModalStyles();

  const deliveryList = useQuery<{
    deliveryPoints: Array<IDeliveryPointData>;
  }>(deliveryPointsQuery);

  const [setDefaultDeliveryPoint] = useMutation<{ setDefaultDeliveryPoint: IDeliveryPointData }>(
    gql`
      mutation setDefaultDeliveryPoint($pointId: String!) {
        setDefaultDeliveryPoint(pointId: $pointId) {
          _id
          displayName
          address
          location
        }
      }
    `,
    {
      update(cache, { data }) {
        const cachedData = cache.readQuery<{
          me: IMemberData;
        }>({ query: meQuery });
        if (data && data.setDefaultDeliveryPoint && cachedData) {
          cache.writeQuery({
            query: meQuery,
            data: { me: { ...cachedData.me, defaultDeliveryPoint: data.setDefaultDeliveryPoint } },
          });
        }
        if (onUpdate) {
          onUpdate();
        }
      },
    }
  );

  return (
    <Modal open={open} className={classes.loadingModal} onEscapeKeyDown={onClose} onBackdropClick={onClose}>
      <Paper className={classes.modalPaper}>
        <Typography component="h3" variant="h4">
          Átadási hely
        </Typography>
        {deliveryList.data && deliveryList.data.deliveryPoints.length === 0 && (
          <Typography variant="h4" component="p">
            Sajnos még nincs itt semmi
          </Typography>
        )}
        {deliveryList.loading && <CircularProgress />}
        {deliveryList.error && deliveryList.error.message}
        {deliveryList.data && (
          <List>
            {deliveryList.data.deliveryPoints.map((p) => (
              <ListItem key={p._id}>
                <ListItemText secondary={`${p.address} (${p.location})`}>{p.displayName}</ListItemText>
                <ListItemAvatar>
                  <IconButton onClick={() => setDefaultDeliveryPoint({ variables: { pointId: p._id } })}>
                    <CheckIcon />
                  </IconButton>
                </ListItemAvatar>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
    </Modal>
  );
};
