import React from "react";
import { CircularProgress, Modal } from "@material-ui/core";

import { useModalStyles } from "./modal.style";

export const LoadingModal: React.FC<{ open: boolean }> = ({ open }) => {
  const classes = useModalStyles();

  return (
    <Modal open={open} className={classes.loadingModal}>
      <CircularProgress />
    </Modal>
  );
};
