import React from "react";

import { HeaderComponent } from "./header.component";

import { useLayoutStyles } from "./loggedInLayout.style";

export const LoggedInLayout: React.FC = ({ children }) => {
  const classes = useLayoutStyles();

  return (
    <div className={classes.root}>
      <HeaderComponent />
      {children}
    </div>
  );
};
