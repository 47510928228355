import React from "react";
import clsx from "clsx";

import { Typography, Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useCommonStyles } from "../common.style";
import { useContactStyles } from "./contact.style";

export const ContactComponent: React.FC = () => {
  const { t } = useTranslation();

  const commonStyles = useCommonStyles();
  const classes = useContactStyles();

  return (
    <Container component="main" maxWidth="xl" className={clsx(commonStyles.mainContent, classes.page)}>
      <Typography variant="h3" component="h2">
        {t("contact.title")}
      </Typography>
      <section className={classes.personalInfos}>
        <Typography variant="body1" component="ul">
          <li>Lengyel Zoltán</li>
          <li>+36-70/453-7310</li>
          <li>zoli@tancoskert.hu</li>
        </Typography>
        <Typography variant="body1" component="ul">
          <li>Subicz Éva</li>
          <li>+36-20/226-3370</li>
          <li>evi@tancoskert.hu</li>
        </Typography>
      </section>
      <section className={classes.links}>
        <Typography variant="h6" component="h3">
          Webshop:
        </Typography>
        <Typography variant="h6" component="a" href="http://rendeles.tancoskert.hu">
          rendeles.tancoskert.hu
        </Typography>
        <br />
        <Typography variant="h6" component="h3">
          Tagi facebook csoport:
        </Typography>
        <Typography variant="h6" component="a" href="https://www.facebook.com/groups/735987077110379">
          Táncoskert tagjai 2021
        </Typography>
        <br />
        <Typography variant="h6" component="h3">
          Átadási naptár:
        </Typography>
        <Typography
          variant="h6"
          component="a"
          href="https://calendar.google.com/calendar/u/0/embed?src=quss42duhe4bhnea9t4ed9qjnk@group.calendar.google.com&ctz=Europe/Budapest&fbclid=IwAR2rCuDfRpsPztAMbG9YkaDRSIftrv5BTEcmrRreKdw0NQKRJSVzXWWRL6A">
          Google calendar
        </Typography>
        <br />
      </section>
      <Typography variant="h6" component="h3">
        Átadópontok:
      </Typography>
      <Typography variant="body1" component="ul">
        <li className={classes.deliveryCity}>
          <Typography variant="subtitle1" component="h4">
            Budapest:
          </Typography>
        </li>
        <li>17:30 - 18:45 Fehér Nyúl Sör főzde (9. kerület, Soroksári út)</li>
        <li>18:00 - 18:30 Veder utca (3. kerület)</li>
        <li>19:15 - 19:45 Kertvárosi Kamra (15. kerület, Székely Elek utca 46.)</li>
        <li>20:30 - 21:00 Pivo söröző (13. kerület, Hegedűs Gyula utca 22.) környéke</li>
        <li className={classes.deliveryCity}>
          <Typography variant="subtitle1" component="h4">
            Debrecen:
          </Typography>
        </li>
        <li>17:00 - 18:00 Malter (Baross Gábor utca 16.)</li>
      </Typography>
    </Container>
  );
};
