import React, { useEffect, useLayoutEffect, useState, useCallback } from "react";

import { Typography, Button, Container, Avatar, TextField, Grid, Modal, CircularProgress } from "@material-ui/core";
import { Link, Redirect, useLocation } from "react-router-dom";
import clsx from "clsx";

import { LockOutlined } from "@material-ui/icons";

import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import jsrp from "jsrp";
import scryptAsync from "scrypt-async";

import { useCommonStyles } from "../common.style";
import { useLoginStyles } from "./login.style";

function fromHex(hexString: string) {
  return new Uint8Array(hexString.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16)));
}

export const LoginComponent: React.FC = () => {
  const commonClasses = useCommonStyles();
  const classes = useLoginStyles();

  const p = useLocation();
  const isRegistration = p.hash === "#regisztracio";

  const [getChallenge] = useMutation<{
    getChallenge: { stretchInfoJSON: string; srpSaltHex: string; B: string };
  }>(gql`
    mutation getChallenge($email: String!) {
      getChallenge(email: $email) {
        stretchInfoJSON
        srpSaltHex
        B
      }
    }
  `);

  const [login, loginState] = useMutation<{ login: { me: { email: string }; serverProof: string } }>(gql`
    mutation login($A: String!, $proof: String!) {
      login(A: $A, proof: $proof) {
        serverProof
        me {
          _id
          email
          fullName
          phoneNumber
          isAdmin
          isEmailVerified
        }
      }
    }
  `);

  const [loginWithGoogle, googleLoginState] = useMutation<{ loginWithGoogle: { email: string } }>(gql`
    mutation login($token: String!) {
      loginWithGoogle(idToken: $token) {
        email
      }
    }
  `);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errors, setErrors] = useState<Array<{ field: string; message: string }>>([]);

  function getErrorForField(name: string): string | undefined {
    const err = errors.find((f) => f.field === name);
    return err && err.message;
  }

  const [register, registerState] = useMutation<{ register: { email: string } }>(gql`
    mutation register(
      $email: String!
      $name: String!
      $phoneNumber: String!
      $srpSalt: String!
      $srpVerifier: String!
      $stretchInfoJSON: String!
    ) {
      register(
        email: $email
        name: $name
        phoneNumber: $phoneNumber
        srpSalt: $srpSalt
        srpVerifier: $srpVerifier
        stretchInfoJSON: $stretchInfoJSON
      )
    }
  `);

  const [sendReminder, reminderState] = useMutation<{ forgotPassword: { email: string } }>(gql`
    mutation forgotPassword($email: String!) {
      forgotPassword(email: $email)
    }
  `);

  useLayoutEffect(() => {
    if (typeof gapi !== "undefined") {
      gapi.load("auth2", function() {
        (gapi as any).signin2.render("googleLoginBtn", {
          "scope":
            "profile https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.emails.read",
          "longtitle": true,
          "onsuccess": (info: any) => {
            loginWithGoogle({ variables: { token: info.getAuthResponse().id_token } });
          },
          "onfailure": console.error,
        });
      });
    }
  }, [loginWithGoogle]);

  const registerSuccess = registerState.called && registerState.data && registerState.data.register;
  const onSubmit = useCallback(
    async (forceLogin = false) => {
      if (registerState.loading || loginState.loading) {
        return;
      }
      const errors = [];
      if (email.length === 0) {
        errors.push({ field: "email", message: "Kérlek adj meg egy email címet!" });
      }
      if (password.length === 0) {
        errors.push({ field: "password", message: "Kérlek adj meg egy jelszót!" });
      }
      if (isRegistration) {
        if (name.length === 0) {
          errors.push({ field: "name", message: "Kérlek add meg a neved!" });
        }
        if (password !== password2) {
          errors.push({ field: "password2", message: "A két jelszó nem egyezik meg!" });
        }
      }

      setErrors(errors);
      if (errors.length !== 0) {
        return;
      }
      const clientSRP = new jsrp.client();
      await new Promise((res) => clientSRP.init({ username: email, password: "" }, res));

      const A = clientSRP.getPublicKey();
      let challenge;
      try {
        const challengeQuery = await getChallenge({ variables: { email, A } });
        challenge = challengeQuery.data!.getChallenge;
      } catch (ex) {
        setErrors([{ field: "password", message: ex?.message || "Valami eltort :(" }]);
        return;
      }

      const stretchInfo = JSON.parse(challenge!.stretchInfoJSON);
      const stretchedPassword = await new Promise((res) =>
        scryptAsync(
          password as any,
          fromHex(stretchInfo.stretchSaltHex) as any,
          Object.assign({ encoding: "binary" }, stretchInfo.params),
          res
        )
      );
      (clientSRP as any).PBuf = stretchedPassword;

      if (isRegistration && !forceLogin) {
        const regInfo = await new Promise<{ salt: string; verifier: string }>((res, rej) =>
          clientSRP.createVerifier((err, data) => {
            if (err) {
              rej(err);
            } else {
              res(data);
            }
          })
        );

        await register({
          variables: {
            email: email,
            name: name,
            phoneNumber: "",
            srpSalt: regInfo.salt,
            srpVerifier: regInfo.verifier,
            stretchInfoJSON: JSON.stringify(stretchInfo),
          },
        });
      } else {
        clientSRP.setSalt(challenge!.srpSaltHex as any);
        clientSRP.setServerPublicKey(challenge.B);

        try {
          const loginRes = await login({
            variables: {
              A,
              proof: clientSRP.getProof(),
            },
          });
          if (loginRes.errors && loginRes.errors.length > 0) {
            for (const err of loginRes.errors) {
              setErrors([{ field: "password", message: err.message }]);
            }
            return;
          }
          if (loginRes.data) {
            clientSRP.checkServerProof(loginRes.data.login.serverProof);
          } else {
            setErrors([{ field: "password", message: "Valami nincs rendben :(" }]);
          }
        } catch (ex) {
          setErrors([{ field: "password", message: "Hibas/nem megerositett email cim vagy jelszo!" }]);
        }
      }
    },
    [email, getChallenge, isRegistration, login, loginState, name, password, password2, register, registerState]
  );

  function sendPasswordReminder() {
    if (email.length === 0) {
      errors.push({ field: "email", message: "Kérlek adj meg egy email címet!" });
    }
    if (reminderState.loading) return;

    sendReminder({ variables: { email } });
  }

  useEffect(() => {
    if (registerSuccess && !loginState.loading && (!loginState.data || !loginState.data.login) && isRegistration) {
      const interval = setInterval(() => {
        if (registerSuccess && !loginState.loading && (!loginState.data || !loginState.data.login) && isRegistration) {
          onSubmit(true);
        }
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [registerState, loginState, email, password, isRegistration, onSubmit, registerSuccess]);

  if ((loginState.called && loginState.data) || (googleLoginState.called && googleLoginState.data)) {
    return <Redirect to="/" />;
  }

  return (
    <Container component="main" className={clsx(commonClasses.mainContent, classes.loginView)}>
      <Modal open={googleLoginState.loading} className={commonClasses.loadingModal}>
        <CircularProgress />
      </Modal>

      <Link to="/" className={commonClasses.plainLink}>
        <Typography component="h1" variant="h2">
          Táncoskert
        </Typography>
      </Link>
      <div className={classes.headerImg} />
      <Avatar className={classes.avatar}>
        <LockOutlined />
      </Avatar>
      <Typography component="h2" variant="h5">
        {isRegistration ? "Regisztráció" : "Bejelentkezés"}
      </Typography>

      <Container maxWidth="sm">
        {registerState.called && registerState.data && registerState.data.register ? (
          <>
            <Typography variant="h3">Sikeres regisztrácio</Typography>
            <Typography variant="body1">
              A bejelentkezéshez előbb meg kell bizonyosodnunk, hogy ez az email cím valóban a tiéd, ezért küldtünk egy
              emailt. A benne lévő linkre kattintás után lesz elérhető az oldalunk.
            </Typography>
          </>
        ) : reminderState.called && reminderState.data && reminderState.data.forgotPassword ? (
          <>
            <Typography variant="h3">Sikeres jelszóemlékeztető</Typography>
            <Typography variant="body1">
              Kiküldtünk egy emailt, amiben egy linkre kattintva megváltoztathatod a jelszavad.
            </Typography>
          </>
        ) : (
          <form
            className={classes.form}
            onSubmit={(ev) => {
              onSubmit();
              ev.preventDefault();
            }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              label="Email cím"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              error={!!getErrorForField("email")}
              helperText={getErrorForField("email")}
              onChange={(ev) => setEmail(ev.currentTarget.value)}
            />
            {isRegistration && (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="name"
                label="Név"
                id="name"
                autoComplete="name"
                error={!!getErrorForField("name")}
                helperText={getErrorForField("name")}
                value={name}
                onChange={(ev) => setName(ev.currentTarget.value)}
              />
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Jelszó"
              type="password"
              id="password"
              error={!!getErrorForField("password")}
              helperText={getErrorForField("password")}
              autoComplete="current-password"
              value={password}
              onChange={(ev) => setPassword(ev.currentTarget.value)}
            />
            {isRegistration && (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password2"
                label="Jelszó megerősítése"
                type="password"
                id="password2"
                error={!!getErrorForField("password2")}
                helperText={getErrorForField("password2")}
                autoComplete="off"
                value={password2}
                onChange={(ev) => setPassword2(ev.currentTarget.value)}
              />
            )}
            {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
            <Button fullWidth variant="contained" color="primary" className={classes.submit} type="submit">
              {registerState.loading || loginState.loading ? (
                <CircularProgress size="1em" />
              ) : isRegistration ? (
                "Regisztráció"
              ) : (
                "Bejelentkezés"
              )}
            </Button>
            <div id="googleLoginBtn" className={clsx(classes.submit, "g-signin2")} data-onsuccess="onSignIn"></div>
            <Grid container>
              <Grid item xs>
                <Button onClick={() => sendPasswordReminder()} variant="text" color="primary">
                  Elfelejtett jelszó
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {isRegistration ? (
                    <Link
                      to="#"
                      onClick={() => {
                        setPassword2("");
                        setName("");
                      }}>
                      Már van fiókod? Jelentkezz be!
                    </Link>
                  ) : (
                    <Link to="#regisztracio">Még nincs fiókod? Regisztrálj!</Link>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </form>
        )}
      </Container>
    </Container>
  );
};
