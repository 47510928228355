import { useMutation } from "@apollo/react-hooks";
import { Avatar, Button, Container, TextField, Typography } from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";
import { gql } from "apollo-boost";
import clsx from "clsx";
import jsrp from "jsrp";
import React, { useState } from "react";
import { useLocation, Link, Redirect } from "react-router-dom";
import scryptAsync from "scrypt-async";

import { useCommonStyles } from "../common.style";

import { useLoginStyles } from "./login.style";

function toHex(arr: Uint8Array) {
  return Array.from(arr)
    .map((x) => ("00" + x.toString(16)).slice(-2))
    .join("");
}

function fromHex(hexString: string) {
  return new Uint8Array(hexString.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16)));
}

export const PasswordResetComponent: React.FC = () => {
  const commonClasses = useCommonStyles();
  const classes = useLoginStyles();

  const p = useLocation();
  const [email, token] = p.hash.substr(1).split(";");

  const [getChallenge] = useMutation<{
    getChallenge: { stretchInfoJSON: string; srpSaltHex: string; B: string };
  }>(gql`
    mutation getChallenge($email: String!) {
      getChallenge(email: $email) {
        stretchInfoJSON
        srpSaltHex
        B
      }
    }
  `);

  const [changePasswordByToken, changePasswordByTokenState] = useMutation<{
    changePasswordByToken: { token: string; srpSalt: string; srpVerifier: string; stretchInfoJSON: string };
  }>(gql`
    mutation changePasswordByToken(
      $token: String!
      $srpSalt: String!
      $srpVerifier: String!
      $stretchInfoJSON: String!
    ) {
      changePasswordByToken(
        token: $token
        srpSalt: $srpSalt
        srpVerifier: $srpVerifier
        stretchInfoJSON: $stretchInfoJSON
      )
    }
  `);

  const [login, loginState] = useMutation<{ login: { me: { email: string }; serverProof: string } }>(gql`
    mutation login($A: String!, $proof: String!) {
      login(A: $A, proof: $proof) {
        serverProof
        me {
          _id
          email
          fullName
          phoneNumber
          isAdmin
          isEmailVerified
        }
      }
    }
  `);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errors, setErrors] = useState<Array<{ field: string; message: string }>>([]);

  function getErrorForField(name: string): string | undefined {
    const err = errors.find((f) => f.field === name);
    return err && err.message;
  }

  async function doLogin() {
    const clientSRP = new jsrp.client();
    await new Promise((res) => clientSRP.init({ username: email, password: "" }, res));

    const A = clientSRP.getPublicKey();
    let challenge;
    try {
      const challengeQuery = await getChallenge({ variables: { email, A } });
      challenge = challengeQuery.data!.getChallenge;
    } catch (ex) {
      setErrors([{ field: "password", message: ex?.message || "Valami eltort :(" }]);
      return;
    }

    const stretchInfo = JSON.parse(challenge!.stretchInfoJSON);
    const stretchedPassword = await new Promise((res) =>
      scryptAsync(
        password as any,
        fromHex(stretchInfo.stretchSaltHex) as any,
        Object.assign({ encoding: "binary" }, stretchInfo.params),
        res
      )
    );

    (clientSRP as any).PBuf = stretchedPassword;

    clientSRP.setSalt(challenge!.srpSaltHex as any);
    clientSRP.setServerPublicKey(challenge.B);

    try {
      const loginRes = await login({
        variables: {
          A,
          proof: clientSRP.getProof(),
        },
      });
      if (loginRes.errors && loginRes.errors.length > 0) {
        for (const err of loginRes.errors) {
          setErrors([{ field: "password", message: err.message }]);
        }
        return;
      }
      if (loginRes.data) {
        clientSRP.checkServerProof(loginRes.data.login.serverProof);
      } else {
        setErrors([{ field: "password", message: "Valami nincs rendben :(" }]);
      }
    } catch (ex) {
      setErrors([{ field: "password", message: "Hibas/nem megerositett email cim vagy jelszo!" }]);
    }
  }

  async function onSubmit() {
    if (changePasswordByTokenState.loading || changePasswordByTokenState.loading) {
      return;
    }
    const errors = [];
    if (password.length === 0) {
      errors.push({ field: "password", message: "Kérlek adj meg egy jelszót!" });
    }
    if (password !== password2) {
      errors.push({ field: "password2", message: "A két jelszó nem egyezik meg!" });
    }

    setErrors(errors);
    if (errors.length !== 0) {
      return;
    }
    const clientSRP = new jsrp.client();
    await new Promise((res) => clientSRP.init({ username: email, password: "" }, res));

    const A = clientSRP.getPublicKey();
    let challenge;
    try {
      const challengeQuery = await getChallenge({ variables: { email: email, A } });
      challenge = challengeQuery.data!.getChallenge;
    } catch (ex) {
      setErrors([{ field: "password", message: ex?.message || "Valami eltort :(" }]);
      return;
    }

    const newStretchSalt = crypto.getRandomValues(new Uint8Array(32));

    const stretchInfo = JSON.parse(challenge!.stretchInfoJSON);
    const stretchedPassword = await new Promise((res) =>
      scryptAsync(
        password as any,
        newStretchSalt as any,
        Object.assign({ encoding: "binary" }, stretchInfo.params),
        res
      )
    );
    (clientSRP as any).PBuf = stretchedPassword;

    const regInfo = await new Promise<{ salt: string; verifier: string }>((res, rej) =>
      clientSRP.createVerifier((err, data) => {
        if (err) {
          rej(err);
        } else {
          res(data);
        }
      })
    );

    const res = await changePasswordByToken({
      variables: {
        token,
        srpSalt: regInfo.salt,
        srpVerifier: regInfo.verifier,
        stretchInfoJSON: JSON.stringify({ ...stretchInfo, stretchSaltHex: toHex(newStretchSalt) }),
      },
    });
    if (res.data?.changePasswordByToken) {
      doLogin();
    } else {
      setErrors(errors.concat([{ field: "email", message: "Lejárt vagy már felhasznált jelszó emlékeztető" }]));
    }
  }

  if (loginState.called && loginState.data) {
    return <Redirect to="/" />;
  }

  return (
    <Container component="main" className={clsx(commonClasses.mainContent, classes.loginView)}>
      <Link to="/" className={commonClasses.plainLink}>
        <Typography component="h1" variant="h2">
          Táncoskert
        </Typography>
      </Link>
      <div className={classes.headerImg} />
      <Avatar className={classes.avatar}>
        <LockOutlined />
      </Avatar>
      <Typography component="h2" variant="h5">
        Elfelejtett jelszó
      </Typography>

      <Container maxWidth="sm">
        <form
          className={classes.form}
          onSubmit={(ev) => {
            onSubmit();
            ev.preventDefault();
          }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            type="email"
            label="Email cím"
            name="email"
            autoComplete="email"
            error={!!getErrorForField("email")}
            helperText={getErrorForField("email")}
            autoFocus
            value={email}
            disabled={true}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Jelszó"
            type="password"
            id="password"
            error={!!getErrorForField("password")}
            helperText={getErrorForField("password")}
            autoComplete="current-password"
            value={password}
            onChange={(ev) => setPassword(ev.currentTarget.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password2"
            label="Jelszó megerősítése"
            type="password"
            id="password2"
            error={!!getErrorForField("password2")}
            helperText={getErrorForField("password2")}
            autoComplete="off"
            value={password2}
            onChange={(ev) => setPassword2(ev.currentTarget.value)}
          />
          {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
          <Button fullWidth variant="contained" color="primary" className={classes.submit} type="submit">
            Jelszó váltás
          </Button>
        </form>
      </Container>
    </Container>
  );
};
