import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

export const useUserOrderListStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      width: "100%",
    },
    editModal: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
      padding: theme.spacing(4),
      height: "100vh",
      overflow: "scroll",

      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },

      "& > .MuiPaper-root": {
        maxWidth: "45vw",

        flexGrow: 1,
        margin: theme.spacing(2),

        [theme.breakpoints.down("md")]: {
          maxWidth: "80vw",
        },
      },
    },
    modalBackdrop: {
      right: "16px !important",
    },
    orderContainer: {
      // width: "100%",
      // height: "100%",
      "&.center": {
        display: "flex",
        justifyContent: "center",
        aligItems: "center",
      },
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
      padding: theme.spacing(4),
      "& > .MuiPaper-root": {
        minWidth: 300,
        maxWidth: 600,
        flexGrow: 1,
        margin: theme.spacing(2),
      },
    },
    countField: {
      width: "4em",
    },
    countInput: {
      textAlign: "right",
    },
    dashboardCard: {
      maxWidth: "100% !important",
      width: "100%",
    },
    negativeBalance: {
      color: theme.palette.error.main,
    },
    nonNegBalance: {
      color: theme.palette.success.main,
    },
  })
);
