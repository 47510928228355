import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import clsx from "clsx";

import {
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Container,
  CardContent,
  CardHeader,
  Card,
  ListItemAvatar,
  Avatar,
  Modal,
  TextField,
  Button,
  CardActions,
} from "@material-ui/core";

import { useParams } from "react-router-dom";

import { useCommonStyles } from "../common.style";
import { useStopViewStyles } from "./deliveryStop.style";

import {
  ITourData,
  IStopData,
  stopQuery,
  tourQuery,
  productsQuery,
  IMemberData,
  usersQuery,
  IDeliveryData,
  DeliveryState,
  IProductData,
} from "../queries";
import { Done, History, Edit, CancelOutlined } from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import { zipWithCount } from "../utils";
import { useModalStyles } from "../modals/modal.style";
import { SeasonContext } from "../season/season.context";

export const DeliveryViewComponent: React.FC = () => {
  const { t } = useTranslation();

  const params = useParams<{ tourId: string; stopId: string }>();

  const userList = useQuery<{
    users: Array<IMemberData>;
  }>(usersQuery);

  const [season] = useContext(SeasonContext);
  const productList = useQuery<{
    products: Array<IProductData>;
  }>(productsQuery, { variables: { season } });

  const deliveryTour = useQuery<{
    deliveryTour: ITourData;
  }>(tourQuery, {
    variables: {
      id: params.tourId,
    },
  });

  const deliveryStop = useQuery<{
    deliveryStop: IStopData;
  }>(stopQuery, {
    variables: {
      id: params.stopId,
    },
  });

  const [currDelivery, setCurrDelivery] = useState<
    (IDeliveryData & { prods: Array<[number, string]>; user?: IMemberData }) | undefined
  >();

  const [setDeliveryState, setDeliveryStateState] = useMutation<{ setDeliveryState: ITourData }>(
    gql`
      mutation setDeliveryState($deliveryId: String!, $state: String!, $note: String!) {
        setDeliveryState(id: $deliveryId, state: $state, note: $note) {
          _id
          state
          note
          email
          productIds
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: stopQuery,
          variables: {
            id: params.stopId,
          },
        },
      ],
      onCompleted: () => setCurrDelivery(undefined),
    }
  );

  const [note, setNote] = useState<string | undefined>();

  const commonStyles = useCommonStyles();
  const stopEditorStyles = useStopViewStyles();
  const modalClasses = useModalStyles();

  if (
    !userList.called ||
    userList.loading ||
    !deliveryTour.called ||
    deliveryTour.loading ||
    !deliveryStop.called ||
    deliveryStop.loading ||
    !productList.called ||
    productList.loading
  ) {
    return (
      <Container component="main" maxWidth="xl" className={commonStyles.mainContent}>
        <CircularProgress />
      </Container>
    );
  }

  if (!deliveryTour.data || !deliveryStop.data || !productList.data || !userList.data) {
    return (
      <Container component="main" maxWidth="xl" className={commonStyles.mainContent}>
        <p>{userList.error && userList.error.message}</p>
        <p>{deliveryTour.error && deliveryTour.error.message}</p>
        <p>{deliveryStop.error && deliveryStop.error.message}</p>
        <p>{productList.error && productList.error.message}</p>
      </Container>
    );
  }

  const users = userList.data.users;
  const tour = deliveryTour.data.deliveryTour;
  const stop = deliveryStop.data.deliveryStop;
  const products = productList.data.products;
  const deliveries = stop?.deliveries
    .map((d) => {
      const u = users.find((u) => u.email === d.email);
      const countedDeliveries = zipWithCount(d.productIds);
      return { ...d, user: u, prods: countedDeliveries };
    })
    .sort(({ user: u1 }, { user: u2 }) =>
      (u1?.fullName || u1?.email || "").localeCompare(u2?.fullName || u2?.email || "")
    );

  function getDeliverySumByState(state: DeliveryState): Array<[string | undefined, number]> {
    const deliveredProds = stop.deliveries
      .filter((d) => d.state === state)
      .flatMap((s) => s.productIds)
      .map((pid) => products.find((p) => p._id === pid)?.displayName);

    return Array.from(new Set(deliveredProds)).map((name) => [
      name || t("errors.unknownProduct"),
      deliveredProds.reduce((acc, cid) => (cid === name ? acc + 1 : acc), 0),
    ]);
  }

  function saveWithState(state: DeliveryState) {
    if (currDelivery) {
      setDeliveryState({
        variables: {
          deliveryId: currDelivery._id,
          note: note === undefined ? currDelivery?.note : note,
          state,
        },
      });
    }
  }
  const scheduledProducts = getDeliverySumByState(DeliveryState.Scheduled);
  const deliveredProducts = getDeliverySumByState(DeliveryState.Ok);
  const rescheduledProducts = getDeliverySumByState(DeliveryState.ReScheduled);
  const noshowProducts = getDeliverySumByState(DeliveryState.NoShow);
  return (
    <Container component="main" maxWidth="xl" className={clsx(commonStyles.mainContent)}>
      <Typography variant="h2">
        {tour.title} - {stop.deliveryPoint.displayName}
      </Typography>
      <Modal className={modalClasses.loadingModal} open={!!currDelivery} onClose={() => setCurrDelivery(undefined)}>
        {setDeliveryStateState.loading ? (
          <CircularProgress />
        ) : (
          <Card className={modalClasses.modalPaper}>
            {currDelivery && (
              <>
                <CardHeader
                  title={currDelivery.user ? currDelivery.user.fullName : currDelivery.email}
                  subheader={
                    <a href={`tel:${currDelivery.user?.phoneNumber}`}>
                      {currDelivery.user?.phoneNumber || "nincs megadva"}
                    </a>
                  }
                />
                <CardContent>
                  <List>
                    {currDelivery.prods.map(([count, pid]) => {
                      const name = products.find((p) => p._id === pid)?.displayName || t("errors.unknownProduct");
                      return (
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>{count}</Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={name} />
                        </ListItem>
                      );
                    })}
                  </List>
                  <TextField
                    value={note === undefined ? currDelivery?.note : note}
                    onChange={(ev) => setNote(ev.target.value)}
                    label={t("delivery.stop.note")}
                    fullWidth={true}
                  />
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    variant={currDelivery.state === DeliveryState.Scheduled ? "contained" : "outlined"}
                    onClick={() => saveWithState(DeliveryState.Scheduled)}>
                    {t("delivery.stop.scheduled")}
                  </Button>
                  <Button
                    color="primary"
                    variant={currDelivery.state === DeliveryState.NoShow ? "contained" : "outlined"}
                    onClick={() => saveWithState(DeliveryState.NoShow)}>
                    {t("delivery.stop.noShow")}
                  </Button>
                  <Button
                    color="primary"
                    variant={currDelivery.state === DeliveryState.Ok ? "contained" : "outlined"}
                    onClick={() => saveWithState(DeliveryState.Ok)}>
                    {t("delivery.stop.ok")}
                  </Button>
                  <Button
                    color="primary"
                    variant={currDelivery.state === DeliveryState.ReScheduled ? "contained" : "outlined"}
                    onClick={() => saveWithState(DeliveryState.ReScheduled)}>
                    {t("delivery.stop.reschedule")}
                  </Button>
                </CardActions>
              </>
            )}
          </Card>
        )}
      </Modal>
      <List className={stopEditorStyles.stopView}>
        {deliveries.map((d) => {
          const { user: u, prods: countedDeliveries } = d;
          return (
            <Card key={d._id} className={stopEditorStyles.deliveryCard}>
              <CardHeader
                title={u ? u.fullName : d.email}
                subheader={<a href={`tel:${u?.phoneNumber}`}>{u?.phoneNumber || "nincs megadva"}</a>}
                action={
                  <IconButton
                    onClick={() => {
                      setCurrDelivery(d);
                    }}>
                    {d.state === DeliveryState.Scheduled ? (
                      <Edit />
                    ) : d.state === DeliveryState.Ok ? (
                      <Done />
                    ) : d.state === DeliveryState.NoShow ? (
                      <CancelOutlined />
                    ) : (
                      <History />
                    )}
                  </IconButton>
                }
              />
              <CardContent>
                <Typography variant="body1">{d.note}</Typography>
                <List>
                  {countedDeliveries.map(([count, pid]) => {
                    const name = products.find((p) => p._id === pid)?.displayName || t("errors.unknownProduct");
                    return (
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>{count}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={name} />
                      </ListItem>
                    );
                  })}
                </List>
              </CardContent>
            </Card>
          );
        })}

        <Card className={stopEditorStyles.deliveryCard}>
          <CardHeader title={"Összesítő"} />
          <CardContent>
            {scheduledProducts.length > 0 && (
              <>
                <Typography variant="body1">Átadni</Typography>
                <List>
                  {scheduledProducts.map(([name, count]) => (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>{count}</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={name} />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
            {deliveredProducts.length > 0 && (
              <>
                <Typography variant="body1">Átvette</Typography>
                <List>
                  {deliveredProducts.map(([name, count]) => (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>{count}</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={name} />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
            {rescheduledProducts.length > 0 && (
              <>
                <Typography variant="body1">Átütemezve</Typography>
                <List>
                  {rescheduledProducts.map(([name, count]) => (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>{count}</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={name} />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
            {noshowProducts.length > 0 && (
              <>
                <Typography variant="body1">Nem jött</Typography>
                <List>
                  {noshowProducts.map(([name, count]) => (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>{count}</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={name} />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </CardContent>
        </Card>
      </List>
    </Container>
  );
};
