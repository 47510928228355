import React, { useContext, useLayoutEffect } from "react";

import { Typography, IconButton, AppBar, Menu, MenuItem, Button } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { Redirect, NavLink } from "react-router-dom";

import clsx from "clsx";

import LogoutIcon from "@material-ui/icons/ExitToApp";

import { useLayoutStyles } from "./loggedInLayout.style";
import { LoadingModal } from "../modals/loadingModal.component";
import { meQuery, IMemberData } from "../queries";
import { useTranslation } from "react-i18next";
import { MoreVert } from "@material-ui/icons";
import { SeasonContext } from "../season/season.context";

export const HeaderComponent: React.FC = () => {
  const classes = useLayoutStyles();

  const [season, setSeason] = useContext(SeasonContext);

  const { t } = useTranslation();

  function logout() {
    if (typeof gapi === "undefined") {
      window.location.href =
        "https://tancoskert-api.lengyel.tech/auth/logout?returnTo=https://tancoskert.lengyel.tech/bejelentkezes";
    } else {
      var auth2 = (gapi as any).auth2.getAuthInstance();
      auth2.signOut().then(function() {
        window.location.href =
          "https://tancoskert-api.lengyel.tech/auth/logout?returnTo=https://tancoskert.lengyel.tech/bejelentkezes";
      }, console.error);
    }
  }

  useLayoutEffect(() => {
    if (typeof gapi !== "undefined") {
      gapi.load("auth2", function() {
        (gapi as any).auth2.init();
      });
    }
  }, []);

  const { loading, error, data } = useQuery<{ me: IMemberData; motd: string }>(meQuery);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar className={classes.header} position="sticky">
      <LoadingModal open={loading} />

      {error && <Redirect to={`/bejelentkezes?error=${error.message}`} />}

      <Typography component="h1" variant="h5">
        {t("header.title")}
      </Typography>
      {data && data.me.isAdmin ? (
        <Typography component="nav" variant="h6" className={clsx(classes.headerNav, classes.wideOnly)}>
          <NavLink to="/termekek">{t("header.products")}</NavLink>|
          <NavLink to="/kiszallitasiPontok">{t("header.deliveryPoints")}</NavLink>|
          <NavLink to="/kiszallitasok">{t("header.deliveryTours")}</NavLink>|
          <NavLink to="/tagok">{t("header.members")}</NavLink>|<NavLink to="/motd">{t("header.motd")}</NavLink>|
          <Button variant="text" onClick={() => setSeason(season === 22 ? 23 : 22)}>
            Evad valtas ({season}-{season + 1} most)
          </Button>
        </Typography>
      ) : (
        <Typography component="nav" variant="h6" className={clsx(classes.headerNav, classes.wideOnly)}>
          <NavLink to="/elojegyzes">{t("header.orders")}</NavLink>
          <NavLink to="/szamlaim">{t("header.memberInvoices")}</NavLink>
          <NavLink to="/adataim">{t("header.memberData")}</NavLink>
          <NavLink to="/atadasaim">{t("header.deliveries")}</NavLink>
          <NavLink to="/kapcsolat">{t("header.contact")}</NavLink>
          {data && data.motd && <NavLink to="/hirdetmeny">{t("header.motd")}</NavLink>}
        </Typography>
      )}
      <div className={clsx(classes.headerUserMenu, classes.wideOnly)}>
        <Typography component="span" variant="h6">
          {data && data.me.fullName}
        </Typography>
        <IconButton onClick={() => logout()} size="small">
          <LogoutIcon />
        </IconButton>
      </div>
      <div className={clsx(classes.headerUserMenu, classes.narrowOnly)}>
        <IconButton
          aria-label="Navigacio"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          size="small">
          <MoreVert />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={!!anchorEl}
          onClose={handleClose}>
          {data && data.me.isAdmin
            ? [
                <MenuItem onClick={handleClose}>
                  <NavLink to="/termekek">{t("header.products")}</NavLink>
                </MenuItem>,
                <MenuItem onClick={handleClose}>
                  <NavLink to="/kiszallitasiPontok">{t("header.deliveryPoints")}</NavLink>
                </MenuItem>,
                <MenuItem onClick={handleClose}>
                  <NavLink to="/kiszallitasok">{t("header.deliveryTours")}</NavLink>
                </MenuItem>,
                <MenuItem onClick={handleClose}>
                  <NavLink to="/tagok">{t("header.members")}</NavLink>
                </MenuItem>,
                <MenuItem onClick={handleClose}>
                  <NavLink to="/motd">{t("header.motd")}</NavLink>
                </MenuItem>,
              ]
            : [
                <MenuItem onClick={handleClose}>
                  <NavLink to="/elojegyzes">{t("header.orders")}</NavLink>
                </MenuItem>,
                <MenuItem onClick={handleClose}>
                  <NavLink to="/atadasaim">{t("header.deliveries")}</NavLink>
                </MenuItem>,
                <MenuItem onClick={handleClose}>
                  <NavLink to="/kapcsolat">{t("header.contact")}</NavLink>
                </MenuItem>,
                <MenuItem onClick={handleClose}>
                  <NavLink to="/adataim">{t("header.memberData")}</NavLink>
                </MenuItem>,
              ]}
          <MenuItem onClick={() => logout()}>
            <NavLink to="#">{t("header.logout")}</NavLink>
          </MenuItem>
        </Menu>
      </div>
    </AppBar>
  );
};
