import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Container,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

import AddIcon from "@material-ui/icons/Add";

import { useParams } from "react-router-dom";

import { useCommonStyles } from "../common.style";
import { deliveryPointsQuery, IDeliveryPointData, ITourData, tourQuery, IProductData, productsQuery } from "../queries";
import { Delete, Edit, CalendarToday, Done } from "@material-ui/icons";
import { ApprovalDialog } from "../modals/approvalDialog.component";
import { SeasonContext } from "../season/season.context";

export const DeliveryTourEditor: React.FC = () => {
  const params = useParams<{ id: string }>();

  const [season] = useContext(SeasonContext);
  const productList = useQuery<{
    products: Array<IProductData>;
  }>(productsQuery, { variables: { season } });

  const points = useQuery<{
    deliveryPoints: Array<IDeliveryPointData>;
  }>(deliveryPointsQuery);

  const deliveryTour = useQuery<{
    deliveryTour: ITourData;
  }>(tourQuery, {
    variables: {
      id: params.id,
    },
  });

  const [updateDeliveryTour] = useMutation<{ updateDeliveryTour: ITourData }>(
    gql`
      mutation updateDeliveryTour($id: String!, $title: String!, $date: String!) {
        updateDeliveryTour(id: $id, title: $title, date: $date) {
          _id
          title
          date
          stops {
            _id
            deliveryPoint {
              _id
              displayName
            }
            deliveries {
              _id
              email
              productIds
            }
          }
        }
      }
    `
  );

  const [deleteIntent, setDeleteIntent] = useState<string | undefined>();
  const [deleteDeliveryStop] = useMutation<{ deleteDeliveryStop: ITourData }>(
    gql`
      mutation deleteDeliveryStop($id: String!) {
        deleteDeliveryStop(id: $id) {
          _id
          date
          stops {
            _id
            deliveryPoint {
              _id
              displayName
            }
            deliveries {
              _id
              email
              productIds
            }
          }
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: tourQuery,
          variables: {
            id: params.id,
          },
        },
      ],
    }
  );

  const [addDeliveryStop] = useMutation<{ addDeliveryStop: ITourData }>(
    gql`
      mutation addDeliveryStop($tourId: String!, $pointId: String!) {
        addDeliveryStop(tourId: $tourId, pointId: $pointId) {
          _id
          date
          stops {
            _id
            deliveryPoint {
              _id
              displayName
            }
            deliveries {
              _id
              email
              productIds
            }
          }
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: tourQuery,
          variables: {
            id: params.id,
          },
        },
      ],
    }
  );

  const commonStyles = useCommonStyles();

  if (!deliveryTour.called || deliveryTour.loading || !points.called || points.loading) {
    return (
      <Container component="main" maxWidth="xl" className={commonStyles.mainContent}>
        <CircularProgress />
      </Container>
    );
  }

  if (!deliveryTour.data || !points.data) {
    return (
      <Container component="main" maxWidth="xl" className={commonStyles.mainContent}>
        <p>{deliveryTour.error && deliveryTour.error.message}</p>
        <p>{points.error && points.error.message}</p>
      </Container>
    );
  }

  const tour = deliveryTour.data.deliveryTour;

  return (
    <Container component="main" maxWidth="xl" className={commonStyles.mainContent}>
      <Typography variant="h2">
        {tour.title}
      </Typography>
      <ApprovalDialog
        titleKey="tourEditor.deleteStop.title"
        contentKey="tourEditor.deleteStop.content"
        actionKey="tourEditor.deleteStop.doDelete"
        cancelAction={() => setDeleteIntent(undefined)}
        continueAction={() =>
          deleteDeliveryStop({ variables: { id: deleteIntent } }).then(() => setDeleteIntent(undefined))
        }
        open={!!deleteIntent}
        isDestructive={true}
      />
      <KeyboardDatePicker
        value={tour.date}
        onChange={(d) =>
          d && updateDeliveryTour({ variables: { id: tour._id, title: tour.title, date: d.toISOString() } })
        }
      />
      <List>
        {points.data &&
          points.data.deliveryPoints
            .sort((pa, pb) => {
              const aInd = tour.stops.findIndex((s) => s.deliveryPoint._id === pa._id);
              const bInd = tour.stops.findIndex((s) => s.deliveryPoint._id === pb._id);

              return bInd === -1 ? -1 : aInd === -1 ? 1 : aInd - bInd;
            })
            .map((p) => {
              const stop = tour.stops.find((s) => s.deliveryPoint._id === p._id);
              const prodIds = new Set<string>();
              if (stop && productList.data) {
                for (const d of stop.deliveries) {
                  d.productIds.forEach((id) => prodIds.add(id));
                }
              }
              const prods = Array.from(prodIds.values())
                .map((id) => productList.data?.products.find((p) => p._id === id))
                .filter((p) => !!p) as Array<IProductData>;
              let stopCalendarLink: string | undefined;
              if (stop && p.calendarId) {
                const query = new URLSearchParams();
                const date = new Date(Date.parse(tour.date));
                const dateStr = `${date.getFullYear()}${date
                  .getMonth()
                  .toString()
                  .padStart(2, "0")}${date
                  .getDay()
                  .toString()
                  .padStart(2, "0")}`;
                query.append("action", "TEMPLATE");
                query.append("src", p.calendarId);
                query.append("text", "Kiszállítás");
                query.append("dates", `${dateStr}/${dateStr}`);
                query.append(
                  "details",
                  `${p.address}\nSzallítunk:\n${prods.map((p) => `  ${p.displayName}`).join("\n")}`
                );
                query.append("location", p.address);
                query.append("trp", "false");
                query.append("sprop", "https://tancoskert.lengyel.tech");
                if (stop) {
                  query.append("add", stop?.deliveries.map((d) => d.email).join(","));
                }
                stopCalendarLink = `http://www.google.com/calendar/event?` + query.toString();
              }
              return (
                <ListItem key={p._id}>
                  <ListItemText secondary={`${p.address} (${p.location})`}>{p.displayName}</ListItemText>
                  <ListItemSecondaryAction>
                    {stop !== undefined ? (
                      <>
                        {stopCalendarLink && (
                          <IconButton href={stopCalendarLink} target="_blank">
                            <CalendarToday />
                          </IconButton>
                        )}
                        <IconButton href={`/kiszallitasok/${tour._id}/${stop._id}`}>
                          <Edit />
                        </IconButton>
                        <IconButton href={`/atadas/${tour._id}/${stop._id}`}>
                          <Done />
                        </IconButton>
                        <IconButton onClick={() => setDeleteIntent(stop._id)}>
                          <Delete />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton onClick={() => addDeliveryStop({ variables: { tourId: tour._id, pointId: p._id } })}>
                        <AddIcon />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
      </List>
    </Container>
  );
};
