import React, { useState } from "react";

import { TextField, Typography, Container, InputAdornment, CircularProgress } from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { LoadingModal } from "../modals/loadingModal.component";

import { useCommonStyles } from "../common.style";
import { useMemberDataStyles } from "./memberData.style";
import { gql } from "apollo-boost";
import { Check } from "@material-ui/icons";

export const BillingInfoFormComponent: React.FC<{ email?: string }> = ({ email }) => {
  const commonStyles = useCommonStyles();
  const classes = useMemberDataStyles();

  const { t } = useTranslation();

  const billingState = useQuery<{
    billingInfo: {
      email: string;
      name: string;
      zip: string;
      city: string;
      address: string;
    };
  }>(
    gql`
      query billingInfo($email: String) {
        billingInfo(email: $email) {
          _id
          email
          name
          zip
          city
          address
        }
      }
    `,
    {
      variables: {
        email,
      },
    }
  );
  /*
    @Args('email') email: string,
    @Args('name') name: string,
    @Args('zip') zip: string,
    @Args('city') city: string,
    @Args('address') address: string,
     */
  const [saveBillingInfo] = useMutation(gql`
    mutation saveBillingInfo($email: String, $name: String, $zip: String, $city: String, $address: String) {
      saveBillingInfo(update: { email: $email, name: $name, zip: $zip, city: $city, address: $address }) {
        _id
        email
        name
        zip
        city
        address
      }
    }
  `);

  function useSavedField<T>(fieldName: string, defVal: T): [T, (v: T) => void] {
    const [field, setter] = useState<T>(defVal);
    const [save] = useDebouncedCallback((val: T) => {
      saveBillingInfo({
        variables: {
          [fieldName]: val,
          email: email,
        },
      });
    }, 1000);

    return [
      field,
      (val: T) => {
        setter((ov) => {
          if ((typeof ov === "string" ? ov.trim() : ov) !== (typeof val === "string" ? val.trim() : val)) {
            save(val);
          }
          return val;
        });
      },
    ];
  }

  const [name, setName] = useSavedField<string | undefined>("name", undefined);
  const [zip, setZip] = useSavedField<string | undefined>("zip", undefined);
  const [city, setCity] = useSavedField<string | undefined>("city", undefined);
  const [address, setAddress] = useSavedField<string | undefined>("address", undefined);

  if (billingState.loading || !billingState.data || !billingState.data.billingInfo) {
    return (
      <Container component="main" maxWidth="xl" className={commonStyles.mainContent}>
        <LoadingModal open={billingState.loading} />;
      </Container>
    );
  }

  const info = billingState.data.billingInfo;

  return (
    <form
      className={classes.form}
      onSubmit={(ev) => {
        ev.preventDefault();
      }}>
      <Typography variant="h2">{t("memberData.billingInfoTitle")}</Typography>
      <TextField
        label={t("memberData.billing.name")}
        value={name === undefined ? info.name : name}
        required={true}
        onChange={(ev) => setName(ev.currentTarget.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!name || (info.name && info.name.trim() === name.trim()) ? <Check /> : <CircularProgress size="1em" />}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label={t("memberData.billing.zip")}
        value={zip === undefined ? info.zip : zip}
        required={true}
        onChange={(ev) => setZip(ev.currentTarget.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!zip || (info.zip && info.zip.trim() === zip.trim()) ? <Check /> : <CircularProgress size="1em" />}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label={t("memberData.billing.city")}
        value={city === undefined ? info.city : city}
        required={true}
        onChange={(ev) => setCity(ev.currentTarget.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!city || (info.city && info.city.trim() === city.trim()) ? <Check /> : <CircularProgress size="1em" />}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label={t("memberData.billing.address")}
        value={address === undefined ? info.address : address}
        required={true}
        onChange={(ev) => setAddress(ev.currentTarget.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!address || (info.address && info.address.trim() === address.trim()) ? (
                <Check />
              ) : (
                <CircularProgress size="1em" />
              )}
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};
