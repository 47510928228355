import React, { useContext, useState } from "react";
import clsx from "clsx";

import { useQuery } from "@apollo/react-hooks";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  List,
  ListItem,
  Container,
  ListItemText,
  CardActions,
  Button,
  Snackbar,
  ListItemAvatar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

import { useCommonStyles } from "../common.style";
import {
  DeliveryState,
  IOrderData,
  IProductData,
  myOrdersQuery,
  OrderPaymentState,
  OrderState,
  productsQuery,
} from "../queries";
import { useMemberDeliveriesStyles as useMemberDashboardStyles } from "./memberDashboard.style";
import { count, sum, zipWithCount } from "../utils";
import { LoadingModal } from "../modals/loadingModal.component";
import { BalanceCardComponent } from "./balanceCard.component";
import { SeasonContext } from "../season/season.context";
import { OrderEditComponent } from "../orders/orderEdit.component";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { ErrorModal } from "../modals/errorModal.component";
import { Warning } from "@material-ui/icons";

export const MemberDashboardComponent: React.FC = () => {
  const orderList = useQuery<{
    myOrders: Array<IOrderData>;
  }>(myOrdersQuery);

  const [season] = useContext(SeasonContext);
  const productList = useQuery<{
    products: Array<IProductData>;
  }>(productsQuery, { variables: { season } });

  const { t } = useTranslation();

  const commonStyles = useCommonStyles();
  const classes = useMemberDashboardStyles();

  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  const [notification, setNotification] = useState<"saved" | "filed" | undefined>();
  const [draftOrder, setDraftOrder] = useState<
    Omit<IOrderData, "memberEmail" | "unscheduledProducts" | "defaultDeliveryPoint">
  >();

  function addOrder() {
    setDraftOrder({
      _id: "new",
      createdDate: new Date().toISOString(),
      state: OrderState.Created,
      products: [],
      invoices: [],
      season,
      deliveries: [],
      prepayments: [],
      paymentState: OrderPaymentState.MonthUninvoiced,
    });
  }

  const [errors, setErrors] = useState<Array<string> | undefined>(undefined);
  const [fileOrder] = useMutation<{ fileOrder: IOrderData }>(
    gql`
      mutation fileOrder($id: String!) {
        fileOrder(orderId: $id) {
          _id
          memberEmail
          products {
            productId
            count
            unitPrice
          }
          state
          createdDate
          offerDate
          acceptDate
          completeDate
          offerPriceHUF
          userNote
        }
      }
    `,
    {
      refetchQueries: [{ query: myOrdersQuery }],
    }
  );

  async function file(id: string) {
    try {
      const res = await fileOrder({ variables: { id } });
      setNotification("filed");
      return res;
    } catch (ex) {
      if (
        ex.graphQLErrors &&
        ex.graphQLErrors[0].extensions.exception.validationErrors &&
        ex.graphQLErrors[0].extensions.exception.validationErrors[0].constraints
      ) {
        const constraints = ex.graphQLErrors[0].extensions.exception.validationErrors[0].constraints;
        setErrors(Array.from(Object.keys(constraints)).map((k) => constraints[k]));
      }
    }
  }

  const orders = orderList.data?.myOrders;
  const products = productList.data?.products;

  if (!orders || !products) {
    return <LoadingModal open={true} />;
  }

  const deliveries = orders.flatMap((o) => o.deliveries);

  const dateOrderedDeliveries = deliveries
    .map((d) => ({
      ...d,
      date: new Date(Date.parse(d.date)),
    }))
    .filter((d) => d.date >= startOfDay)
    .sort((a, b) => a.date.valueOf() - b.date.valueOf());

  const nextDelivery = dateOrderedDeliveries[0];
  const nextDeliveredProductIds = zipWithCount(
    nextDelivery
      ? dateOrderedDeliveries
          .filter((d) => d.date === nextDelivery.date)
          .flatMap((d) => d.productIds)
          .map((pid) => products.find((prod) => prod._id === pid)?.displayName || "Ismeretlen")
      : []
  );

  const seasons = Array.from(
    new Set<number>([...orders.map((o: IOrderData) => o.season), season])
  );
  const ordersInProgress = orders.filter((o: IOrderData) => o.state === OrderState.Accepted && o.season === season);
  const editableOrder = orders.find(
    (o: IOrderData) =>
      [OrderState.Created, OrderState.Filed, OrderState.OfferSent].includes(o.state) && o.season === season
  );

  return (
    <Container component="main" maxWidth="xl" className={clsx(commonStyles.mainContent, classes.page)}>
      <ErrorModal
        onClose={() => setErrors(undefined)}
        error={errors && errors[0]}
        url={errors && errors[0] !== "There is an active invoice for this order already" ? "/adataim" : ""}
      />
      <Typography variant="h3" component="h2">
        {t("memberDeliveryList.title")}
      </Typography>
      <section className={classes.dashboard}>
        <Card className={classes.dashboardCard}>
          <CardHeader title={`Következő átadásom`} />
          <CardContent>
            {nextDelivery ? (
              <Typography variant="h6">
                {nextDelivery.date.toLocaleDateString()} - {nextDelivery.stopName}
              </Typography>
            ) : (
              <Typography variant="h6">Nincs beütemezve</Typography>
            )}
            {nextDeliveredProductIds.length > 0 && (
              <List>
                {nextDeliveredProductIds.map(([count, displayName]) => (
                  <ListItem key={displayName}>
                    <ListItemText primary={`${displayName} x ${count}`} />
                  </ListItem>
                ))}
              </List>
            )}
          </CardContent>
        </Card>
        <BalanceCardComponent cardProps={{ className: classes.dashboardCard }} ordersInSeason={ordersInProgress} />
        {seasons.map((currSeason) => {
          const ordersInSeason = orders.filter(
            (o: IOrderData) => o.season === currSeason && o.state === OrderState.Accepted
          );

          const uniqueProductIds = new Set(ordersInSeason.flatMap((a) => a.products.map((p) => p.productId)));
          const orderedProducts = Array.from(uniqueProductIds)
            .map((pid) => {
              return {
                id: pid,
                displayName: products.find((prod) => prod._id === pid)?.displayName || "Ismeretlen",
                undelivered: sum(ordersInSeason, (o) => sum(o.unscheduledProducts, (prod) => (prod.productId === pid ? prod.count : 0))),
                count: sum(ordersInSeason, (o) => sum(o.products, (prod) => (prod.productId === pid ? prod.count : 0))),
                scheduled: sum(deliveries, (d) =>
                  d.state === DeliveryState.Scheduled ? count(d.productIds, (deliveryPID) => deliveryPID === pid) : 0
                ),
                delivered: sum(deliveries, (d) =>
                  d.state === DeliveryState.Ok ? count(d.productIds, (deliveryPID) => deliveryPID === pid) : 0
                ),
                noShow: sum(deliveries, (d) =>
                  d.state === DeliveryState.NoShow ? count(d.productIds, (deliveryPID) => deliveryPID === pid) : 0
                ),
              };
            })
            .sort((a, b) => a.displayName.localeCompare(b.displayName));

          return (
            (orderedProducts.length > 0 || currSeason === season) && (
              <Card className={classes.dashboardCard}>
                <CardHeader title={`${currSeason}-${currSeason + 1} szezonból hátra van még:`} />
                <CardContent>
                  <List>
                    {orderedProducts.map((prod) => (
                      <ListItem key={prod.id}>
                        <ListItemText
                          primary={`${prod.displayName} x ${prod.undelivered}`}
                          secondary={
                            prod.scheduled === 0 // prod.scheduled + prod.delivered + prod.noShow === 0
                              ? "Nincs beütemezve"
                              : prod.scheduled > 0
                              ? `Beütemezve: ${prod.scheduled} csomag `
                              : "" /* +
                              (prod.delivered > 0 ? `Atadtunk: ${prod.delivered} csomag ` : "") +
                              (prod.noShow > 0 ? `Nem vettel at: ${prod.noShow} csomag ` : "")*/
                          }
                        />
                      </ListItem>
                    ))}
                    {editableOrder && currSeason === editableOrder.season && editableOrder.products.length > 0 && (
                      <ListItem>
                        <ListItemAvatar>
                          <Warning />
                        </ListItemAvatar>
                        <ListItemText>
                          {editableOrder.state === OrderState.Created
                            ? "Az előjegyzésed még csak piszkozatként van elmentve, a lenti előjegyzés gombbal szerkesztheted majd véglegesítsd ha szeretnéd, hogy megkapjuk."
                            : editableOrder.state === OrderState.Filed
                            ? "Az előjegyzésedet még nem fogadtuk be. Hamarosan átnézzük és visszajelzünk róla. Addig is, a lenti előjegyzés gombbal szerkesztheted."
                            : editableOrder.state === OrderState.OfferSent
                            ? "Az előjegyzésedhez küldtünk ki árajánlatot, a lenti előjegyzés gombbal fogadhatod el, ha megfelel."
                            : ""}
                        </ListItemText>
                      </ListItem>
                    )}
                    {!editableOrder && orderedProducts.length === 0 && (
                      <ListItem>
                        <ListItemAvatar>
                          <Warning />
                        </ListItemAvatar>
                        <ListItemText
                          primary={"Még nem adtál le előjegyzést"}
                          secondary="Az alul lévő gombokkal készíthetsz, szerkesztheted és véglegesítheted az előjegyzésedet"
                        />
                      </ListItem>
                    )}
                  </List>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  {currSeason === season && <Button
                    size="small"
                    color="primary"
                    onClick={() => (editableOrder ? setDraftOrder(editableOrder) : addOrder())}
                    variant="outlined">
                    Előjegyzés
                  </Button>}
                </CardActions>
              </Card>
            )
          );
        })}
      </section>
      {draftOrder && (
        <OrderEditComponent
          order={draftOrder}
          fileOrder={(id) => file(id)}
          onClose={(saved) => {
            if (saved) {
              setNotification((ol) => (ol === "filed" ? ol : "saved"));
            }
            
            setDraftOrder(undefined);
          }}
        />
      )}
      <Snackbar open={!!notification} autoHideDuration={6000} onClose={() => setNotification(undefined)}>
        <Alert elevation={6} variant="filled" severity="success" onClose={() => setNotification(undefined)}>
          {notification === "saved"
            ? "Sikeresen mentetted az előjegyzésedet"
            : notification === "filed"
            ? "Sikeresen véglegesítetted az előjegyzésedet"
            : ""}
        </Alert>
      </Snackbar>
    </Container>
  );
};
