import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import clsx from "clsx";

import {
  Typography,
  CircularProgress,
  Container,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Select,
  MenuItem,
  TableBody,
  FormControl,
  InputLabel,
  Modal,
} from "@material-ui/core";

import { useCommonStyles } from "../common.style";
import { useUserOrderListStyles } from "./memberList.style";
import {
  IOrderData,
  productsQuery,
  IProductData,
  OrderState,
  usersQuery,
  IMemberData,
  allOrdersQuery,
  OrderPaymentState,
} from "../queries";
import {
  Flag,
  FlagOutlined,
  Check,
  ShoppingBasket,
  ShoppingBasketOutlined,
  NotInterested,
  Edit,
  DeleteForever,
  Send,
  Visibility,
  AttachMoney,
  DoneAll,
  Error,
  Mail,
  Done,
  Lock,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { OrderEditComponent } from "../orders/orderEdit.component";
import { OrderCardComponent } from "../orders/orderCard.component";
import { sum } from "../utils";
import { PrepaymentModal } from "../modals/prepayment.modal.component";
import { ErrorModal } from "../modals/errorModal.component";
import { SeasonContext } from "../season/season.context";

export const MemberListComponent: React.FC = () => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<Array<string> | undefined>(undefined);

  const userList = useQuery<{
    users: Array<IMemberData>;
  }>(usersQuery);

  const [season] = useContext(SeasonContext);
  const productList = useQuery<{
    products: Array<IProductData>;
  }>(productsQuery, {variables: { season}});

  const orderList = useQuery<{
    orders: IOrderData[];
  }>(allOrdersQuery, {
    variables: {
      allowedStates: [
        OrderState.Created,
        OrderState.Filed,
        OrderState.OfferSent,
        OrderState.Accepted,
        OrderState.Completed,
      ],
    },
  });

  const [sendOffer] = useMutation<{ sendOffer: IOrderData }>(
    gql`
      mutation sendOffer($id: String!) {
        sendOffer(orderId: $id) {
          _id
          memberEmail
          products {
            productId
            count
          }
          state
          createdDate
          offerDate
          acceptDate
          completeDate
          offerPriceHUF
          userNote
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: allOrdersQuery,
          variables: {
            allowedStates: [
              OrderState.Created,
              OrderState.Filed,
              OrderState.OfferSent,
              OrderState.Accepted,
              OrderState.Completed,
            ],
          },
        },
      ],
    }
  );

  const [deleteOrder] = useMutation<{
    deleteOrder: IOrderData;
  }>(
    gql`
      mutation deleteOrder($id: String!) {
        deleteOrder(orderId: $id) {
          _id
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: allOrdersQuery,
          variables: {
            allowedStates: [
              OrderState.Created,
              OrderState.Filed,
              OrderState.OfferSent,
              OrderState.Accepted,
              OrderState.Completed,
            ],
          },
        },
      ],
    }
  );

  const [completeOrder] = useMutation<{
    completeOrder: IOrderData;
  }>(
    gql`
      mutation completeOrder($id: String!) {
        completeOrder(orderId: $id) {
          _id
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: allOrdersQuery,
          variables: {
            allowedStates: [
              OrderState.Created,
              OrderState.Filed,
              OrderState.OfferSent,
              OrderState.Accepted,
              OrderState.Completed,
            ],
          },
        },
      ],
    }
  );

  const [hideOrder] = useMutation<{ sendOffer: IOrderData }>(
    gql`
      mutation hideOrder($id: String!, $val: Boolean!) {
        hideOrder(orderId: $id, val: $val) {
          _id
          hidden
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: allOrdersQuery,
          variables: {
            allowedStates: [
              OrderState.Created,
              OrderState.Filed,
              OrderState.OfferSent,
              OrderState.Accepted,
              OrderState.Completed,
            ],
          },
        },
      ],
    }
  );

  const [askInvoice, askInvoiceState] = useMutation(
    gql`
      mutation askForInvoice($id: String!, $count: Int!) {
        askForInvoice(id: $id, count: $count) {
          _id
          invoices {
            _id
            id
            paid
            price
            issued
          }
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: allOrdersQuery,
          variables: {
            allowedStates: [
              OrderState.Created,
              OrderState.Filed,
              OrderState.OfferSent,
              OrderState.Accepted,
              OrderState.Completed,
            ],
          },
        },
      ],
    }
  );

  async function invoice(id: string, count: number) {
    if (askInvoiceState.loading) return;
    try {
      const res = await askInvoice({ variables: { id, count } });
      return res;
    } catch (ex) {
      if (
        ex.graphQLErrors &&
        ex.graphQLErrors[0].extensions.exception.validationErrors &&
        ex.graphQLErrors[0].extensions.exception.validationErrors[0].constraints
      ) {
        const constraints = ex.graphQLErrors[0].extensions.exception.validationErrors[0].constraints;
        setErrors(Array.from(Object.keys(constraints)).map((k) => constraints[k]));
      } else {
        setErrors([JSON.stringify(ex, null, 2)]);
      }
    }
  }

  const commonStyles = useCommonStyles();
  const classes = useUserOrderListStyles();

  const [showHidden, setShowHidden] = useState(false);
  const [showAllOrders, setShowAllOrders] = useState(false);

  const [draftOrder, setDraftOrder] = useState<IOrderData>();
  const [prepaymentOrder, setPrepaymentOrder] = useState<IOrderData>();

  const [nameFilter, setNameFilter] = useState("");
  const [stateFilter, setStateFilter] = useState("");
  const [verifiedFilter, setVerifiedFilter] = useState<boolean | undefined>(undefined);
  const [hasFiledOrder, setHasOrderFilter] = useState<boolean | undefined>(undefined);
  const [locationFilter, setLocationFilter] = useState("");

  const queries = [userList, productList, orderList];
  if (queries.some((q) => !q.called || q.loading)) {
    return (
      <Container component="main" maxWidth="xl" className={commonStyles.mainContent}>
        <CircularProgress />
      </Container>
    );
  }

  if (queries.some((q) => !q.data || q.error)) {
    return (
      <Container component="main" maxWidth="xl" className={commonStyles.mainContent}>
        {queries.map((q) => (
          <p>{q.error ? JSON.stringify(q.error) : "not loaded"}</p>
        ))}
      </Container>
    );
  }

  const users = userList.data!.users.filter(
    (u) =>
      (u.email.toLocaleLowerCase().includes(nameFilter.toLocaleLowerCase()) ||
        u.fullName.toLocaleLowerCase().includes(nameFilter.toLocaleLowerCase())) &&
      (locationFilter === "" || (u.defaultDeliveryPoint && u.defaultDeliveryPoint.displayName === locationFilter)) &&
      (verifiedFilter === undefined || u.isEmailVerified === verifiedFilter)
  );
  const orders = orderList.data!.orders.filter(o => o.season === season);

  const locs = Array.from(
    new Set(users.map((u) => u.defaultDeliveryPoint && u.defaultDeliveryPoint.displayName))
  ).filter((l) => !!l);

  const usersWithOrders = (users.map((u) => {
    const userOrders = orders.filter(
      (o) =>
        o.memberEmail === u.email &&
        (showAllOrders || [OrderState.Filed, OrderState.OfferSent, OrderState.Accepted].includes(o.state))
    );
    return [u, userOrders];
  }) as Array<[IMemberData, IOrderData[]]>).filter(
    ([u, ol]) =>
      hasFiledOrder === undefined ||
      (ol.length > 0 && ol.some((o) => [OrderState.Filed, OrderState.OfferSent, OrderState.Accepted].includes(o.state))
        ? hasFiledOrder
        : !hasFiledOrder)
  );

  return (
    <Container component="main" maxWidth="xl" className={clsx(commonStyles.mainContent, classes.page)}>
      <ErrorModal onClose={() => setErrors(undefined)} error={errors && errors[0]} url={""} />
      <PrepaymentModal
        order={prepaymentOrder && orders.find((o) => o._id === prepaymentOrder._id)}
        onSuccess={() => setPrepaymentOrder(undefined)}
      />
      <Typography variant="h3" component="h2">
        {t("members.list.title")}
        <IconButton onClick={() => setShowHidden((v) => !v)}>{showHidden ? <Flag /> : <FlagOutlined />}</IconButton>
        <IconButton onClick={() => setShowAllOrders((v) => !v)}>
          {showAllOrders ? <ShoppingBasket /> : <ShoppingBasketOutlined />}
        </IconButton>
      </Typography>
      {users.length === 0 && <Typography>{t("members.list.empty")}</Typography>}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TextField value={nameFilter} onChange={(e) => setNameFilter(e.target.value)} placeholder="Nev" />
            </TableCell>
            <TableCell>Telefon</TableCell>
            <TableCell>
              <FormControl>
                <InputLabel>Megerositett email</InputLabel>
                <Select
                  fullWidth={true}
                  value={verifiedFilter === undefined ? "" : verifiedFilter ? "yes" : "no"}
                  onChange={(e) => setVerifiedFilter(e.target.value ? e.target.value === "yes" : undefined)}>
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="yes">Igen</MenuItem>
                  <MenuItem value="no">Nem</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <InputLabel>Beadott rendeles</InputLabel>
              <Select
                fullWidth={true}
                value={hasFiledOrder === undefined ? "" : hasFiledOrder ? "yes" : "no"}
                onChange={(e) => setHasOrderFilter(e.target.value ? e.target.value === "yes" : undefined)}>
                <MenuItem value=""></MenuItem>
                <MenuItem value="yes">Igen</MenuItem>
                <MenuItem value="no">Nem</MenuItem>
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Kiszallitasi hely</InputLabel>
              <Select
                fullWidth={true}
                value={locationFilter}
                onChange={(e) => setLocationFilter(e.target.value as string)}>
                <MenuItem value=""></MenuItem>
                {locs.map((dn) => (
                  <MenuItem key={`loc_filter_${dn}`} value={dn}>
                    {dn}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Statusz</InputLabel>
              <Select fullWidth={true} value={stateFilter} onChange={(e) => setStateFilter(e.target.value as string)}>
                <MenuItem value=""></MenuItem>
                {[OrderState.Created, OrderState.Filed, OrderState.OfferSent, OrderState.Accepted].map((state) => (
                  <MenuItem key={`state_filter_${state}`} value={state}>
                    {t(`orders.card.${state}`)}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
            <TableCell>Ar</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersWithOrders.map(([u, ol]) =>
            ol.length > 0
              ? ol
                  .filter((o) => !stateFilter || o.state === stateFilter)
                  .filter((o) => showHidden || !o.hidden)
                  .map((o) => {
                    const sumPaid = sum(o.invoices, (i) => i.paid) + sum(o.prepayments, (p) => p.amount);
                    const sumIssued = o.invoices.reduce((acc, inv) => acc + inv.price, 0);
                    const sumPrice = o.products.reduce((acc, prod) => {
                      const product =
                        productList.data && productList.data.products.find((p) => p._id === prod.productId);
                      return acc + prod.count * (product?.priceHUF || 0);
                    }, 0);
                    return (
                      <TableRow key={`tr_${o._id}`}>
                        <TableCell>
                          {u.fullName} (<a href={`mailto:${u.email}`}>{u.email}</a>)
                        </TableCell>
                        <TableCell>{u.phoneNumber && <a href={`tel:${u.phoneNumber}`}>{u.phoneNumber}</a>}</TableCell>
                        <TableCell>{u.isEmailVerified ? <Check /> : <NotInterested />}</TableCell>
                        <TableCell>{ol.length > 0 ? <Check /> : <NotInterested />}</TableCell>
                        <TableCell>{u.defaultDeliveryPoint && u.defaultDeliveryPoint.displayName}</TableCell>
                        <TableCell>{t(`orders.card.${o.state}`)}</TableCell>
                        <TableCell>
                          {o.paymentState === OrderPaymentState.FullyPaid ? (
                            <>
                              <DoneAll /> ({o.offerPriceHUF || sumPrice})
                            </>
                          ) : (
                            <>
                              {o.paymentState === OrderPaymentState.MonthUninvoiced ? (
                                <Error />
                              ) : o.paymentState === OrderPaymentState.MonthInvoiced ? (
                                <Mail />
                              ) : (
                                <Done />
                              )}
                              {o.offerPriceHUF || sumPrice}
                              {o.invoices ? ` (${sumPaid}/${sumIssued})` : ""}
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => setDraftOrder(o)}>
                            {[OrderState.Created, OrderState.Filed, OrderState.OfferSent, OrderState.Accepted].includes(
                              o.state
                            ) ? (
                              <Edit />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                          {o.state === OrderState.Filed && (
                            <IconButton onClick={() => sendOffer({ variables: { id: o._id } })} title="Ajanlatkuldes">
                              <Send />
                            </IconButton>
                          )}
                          {[OrderState.Filed, OrderState.OfferSent].includes(o.state) && (
                            <IconButton onClick={() => deleteOrder({ variables: { id: o._id } })} title="Torol">
                              <DeleteForever />
                            </IconButton>
                          )}
                          {[OrderState.Accepted].includes(o.state) && (
                            <IconButton onClick={() => completeOrder({ variables: { id: o._id } })} title="Lezar">
                              <Lock />
                            </IconButton>
                          )}
                          <IconButton onClick={() => hideOrder({ variables: { id: o._id, val: !o.hidden } })}>
                            {o.hidden ? <Flag /> : <FlagOutlined />}
                          </IconButton>

                          <IconButton onClick={() => setPrepaymentOrder(o)}>
                            <AttachMoney />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
              : !stateFilter && (
                  <TableRow key={`tr_${u.email}`}>
                    <TableCell>
                      {u.fullName} (<a href={`mailto:${u.email}`}>{u.email}</a>)
                    </TableCell>
                    <TableCell>{u.phoneNumber && <a href={`tel:${u.phoneNumber}`}>{u.phoneNumber}</a>}</TableCell>
                    <TableCell>{u.isEmailVerified ? <Check /> : <NotInterested />}</TableCell>
                    <TableCell>
                      <NotInterested />
                    </TableCell>
                    <TableCell>{u.defaultDeliveryPoint && u.defaultDeliveryPoint.displayName}</TableCell>
                    <TableCell>Nincs beadott</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )
          )}
        </TableBody>
      </Table>
      {draftOrder &&
        ([OrderState.Created, OrderState.Filed, OrderState.OfferSent, OrderState.Accepted].includes(
          draftOrder.state
        ) ? (
          <OrderEditComponent
            order={draftOrder}
            fileOrder={(id) => console.log(id)}
            onClose={() => setDraftOrder(undefined)}
            invoice={invoice}
          />
        ) : (
          <Modal
            open={true}
            onBackdropClick={() => setDraftOrder(undefined)}
            onEscapeKeyDown={() => setDraftOrder(undefined)}>
            <OrderCardComponent
              key={draftOrder._id + "_draft"}
              order={draftOrder}
              origOrder={draftOrder}
              setNote={() => {}}
              changeProdCount={() => {}}
              canEdit={false}
              actions={[
                draftOrder.state === OrderState.Accepted &&
                draftOrder.offerPriceHUF &&
                draftOrder.offerPriceHUF >
                  draftOrder.invoices.reduce((s, c) => s + c.price, 0) + draftOrder.offerPriceHUF / 12
                  ? {
                      primary: true,
                      text: t("orders.card.getInvoice1"),
                      cb: () => invoice(draftOrder._id, 1),
                    }
                  : undefined,
                draftOrder.state === OrderState.Accepted &&
                draftOrder.offerPriceHUF &&
                draftOrder.offerPriceHUF >
                  draftOrder.invoices.reduce((s, c) => s + c.price, 0) + draftOrder.offerPriceHUF / 4
                  ? {
                      primary: true,
                      text: t("orders.card.getInvoice3"),
                      cb: () => invoice(draftOrder._id, 3),
                    }
                  : undefined,
                draftOrder.state === OrderState.Accepted &&
                draftOrder.offerPriceHUF &&
                draftOrder.offerPriceHUF >
                  draftOrder.invoices.reduce((s, c) => s + c.price, 0) + draftOrder.offerPriceHUF / 2
                  ? {
                      primary: true,
                      text: t("orders.card.getInvoice12"),
                      cb: () => invoice(draftOrder._id, 12),
                    }
                  : undefined,
              ]}
            />
          </Modal>
        ))}
    </Container>
  );
};
