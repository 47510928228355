import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LoginComponent } from "./login/login.component";
import { PasswordResetComponent } from "./login/passwordReset.component";
import { LoggedInLayout } from "./layout/loggedInLayout.component";
import { ProductList } from "./productList/productList.component";
import { DeliveryPointList } from "./deliveryPoints/deliveryPointList.component";
import { EmailVerificationComponent } from "./login/emailVerification.component";
import { MemberInvoicesComponent } from "./memberInvoices/memberInvoices.component";
import { DeliveryTourList } from "./deliveryScheduler/deliveryTourList.component";
import { DeliveryTourEditor } from "./deliveryScheduler/deliveryTourEditor.component";
import { DeliveryStopEditor } from "./deliveryScheduler/deliveryStopEditor.component";
import { DeliveryViewComponent } from "./deliveryScheduler/deliveryStop.component";
import { MemberListComponent } from "./members/memberList.component";
import { MemberDataComponent } from "./memberData/memberData.component";
import { MOTDEditorComponent } from "./motd/motdEditor.component";
import { MOTDComponent } from "./motd/motd.component";
import { PollEditorComponent } from "./polls/pollEditor.component";
import { MemberDeliveriesComponent } from "./memberDeliveries/memberDeliveries.component";
import { MemberDashboardComponent } from "./memberDashboard/memberDashboard.component";
import { ContactComponent } from "./contact/contact.component";

export const RouterComponent = () => {
  return (
    <Switch>
      <Route path="/bejelentkezes">
        <LoginComponent />
      </Route>
      <Route path="/regisztracio">
        <LoginComponent />
      </Route>
      <Route path="/email-megerosites">
        <EmailVerificationComponent />
      </Route>
      <Route path="/jelszo-visszaallitas">
        <PasswordResetComponent />
      </Route>
      <LoggedInLayout>
        <Switch>
          <Route path="/tagok">
            <MemberListComponent />
          </Route>
          <Route path="/termekek">
            <ProductList />
          </Route>
          <Route path="/szamlaim">
            <MemberInvoicesComponent />
          </Route>
          <Route path="/adataim">
            <MemberDataComponent />
          </Route>
          <Route path="/hirdetmeny">
            <MOTDComponent />
          </Route>
          <Route path="/tagok">
            <MemberListComponent />
          </Route>
          <Route path="/kiszallitasiPontok/">
            <DeliveryPointList />
          </Route>
          <Route path="/kiszallitasok/:tourId/:stopId">
            <DeliveryStopEditor />
          </Route>
          <Route path="/atadas/:tourId/:stopId">
            <DeliveryViewComponent />
          </Route>
          <Route path="/kiszallitasok/:id">
            <DeliveryTourEditor />
          </Route>
          <Route path="/kiszallitasok/">
            <DeliveryTourList />
          </Route>
          <Route path="/kapcsolat">
            <ContactComponent />
          </Route>
          <Route path="/atadasaim">
            <MemberDeliveriesComponent />
          </Route>
          <Route path="/elojegyzes">
            <MemberDashboardComponent />
          </Route>
          <Route path="/motd">
            <MOTDEditorComponent />
          </Route>
          <Route path="/polls">
            <PollEditorComponent />
          </Route>
          <Route>
            <Redirect to="/hirdetmeny" />
          </Route>
        </Switch>
      </LoggedInLayout>
    </Switch>
  );
};
